import loadable from "@loadable/component";
import { loadableAuthParams } from "@components/Skeleton/loadableParams";

const Auth = {
  Welcome: loadable(async () => import("./Welcome"), loadableAuthParams),
  SignUpConsumer: loadable(
    () => import("./SignUpConsumer"),
    loadableAuthParams
  ),
  Forgot: loadable(() => import("./Forgot"), loadableAuthParams),
  Sso: loadable(() => import("./Sso"), loadableAuthParams),
  Login: loadable(() => import("./Login"), loadableAuthParams),
  Survey: loadable(() => import("./Survey"), loadableAuthParams),
  ExternalUser: loadable(() => import("./ExternalUser"), loadableAuthParams),
  LoginWithUsername: loadable(
    () => import("./LoginWithUsername"),
    loadableAuthParams
  ),
  SignUpWithUsername: loadable(
    () => import("./RegisterWIthUsername"),
    loadableAuthParams
  ),
  YouthAndClass: loadable(
    () => import("./StorSthlm/InitialYouthAndClassPage"),
    loadableAuthParams
  ),
  AnonymousChat: loadable(
    () => import("./StorSthlm/AnonymousChat"),
    loadableAuthParams
  ),
};

export default Auth;
