import config from "@config/config";
import ApiService from "./api";

class CalendarApi extends ApiService {
  constructor() {
    if (CalendarApi.instance) {
      return CalendarApi.instance;
    }

    super(config.calendarApiUrl);
    CalendarApi.instance = this;
  }

  async getCalendarByUserId(accountId, userId) {
    const response = await this.http.get(
      `/accounts/${accountId}/calendars?ownerId=${userId}`
    );
    return response.data;
  }

  async getCalendarByUserIds(accountId, userIds) {
    const response = await this.http.get(
      `/accounts/${accountId}/calendars/userIds?ownerIds=${JSON.stringify(
        userIds
      )}`
    );
    return response.data;
  }

  async getCalendarEventsByCalendarId({
    accountId,
    calendarId,
    dtStart = 0,
    dtEnd = 99999999999999,
    populateParticipants = false,
    participantUserIds,
    globalEventId,
    status = null,
    isForSharedCalendar = false,
  }) {
    const calendarIdParam = `calendarId=${calendarId}`;
    const dtStartParam = `dtStart=${dtStart}`;
    const dtEndParam = `dtEnd=${dtEnd}`;
    const statusParam = status === null ? "" : `&status=${status}`;
    const populateParticipantsParam = `populateParticipants=${populateParticipants}`;
    const globalEventIdParam = globalEventId
      ? `globalEventId=${globalEventId}`
      : null;
    const participantsParam = participantUserIds
      ? participantUserIds.map((v) => `participant=${v}`).join("&")
      : null;
    const isForSharedCalendarParam = `isForSharedCalendar=${isForSharedCalendar}`;

    const response = await this.http.get(
      `/accounts/${accountId}/calendarEvents?${calendarIdParam}&${dtStartParam}${statusParam}&${dtEndParam}&${populateParticipantsParam}${
        participantsParam ? `&${participantsParam}` : ""
      }${
        globalEventIdParam ? `&${globalEventIdParam}` : ""
      }&${isForSharedCalendarParam}`
    );

    return response.data;
  }

  async getCalendarEventById(
    accountId,
    calendarEventId,
    populateParticipants = false,
    isForSharedCalendar = false
  ) {
    const isForSharedCalendarParam = `isForSharedCalendar=${isForSharedCalendar}`;
    const response = await this.http.get(
      `/accounts/${accountId}/calendarEvents/${calendarEventId}${
        populateParticipants
          ? `?populateParticipants=${populateParticipants}`
          : ""
      }${isForSharedCalendar ? `&${isForSharedCalendarParam}` : ""}`
    );
    return response.data;
  }

  async createCalendarEvent(accountId, calendarEvent) {
    const response = await this.http.post(
      `/accounts/${accountId}/calendarEvents`,
      calendarEvent
    );
    return response.data;
  }

  async createCalendarEventMultiple(accountId, calendarEvent) {
    const response = await this.http.post(
      `/accounts/${accountId}/calendarEvents/multi`,
      calendarEvent
    );
    return response.data;
  }

  async createCalendarEventParticipant(accountId, calendarEventParticipant) {
    const response = await this.http.post(
      `/accounts/${accountId}/calendarEvents/${calendarEventParticipant.eventId}/participants`,
      calendarEventParticipant
    );
    return response.data;
  }

  async updateCalendarEvent(accountId, calendarEvent) {
    const response = await this.http.put(
      `/accounts/${accountId}/calendarEvents/${calendarEvent.id}`,
      calendarEvent
    );
    return response.data;
  }

  async getCalendarEventsByOwnerId(
    accountId,
    ownerId,
    { dtStart, dtEnd },
    participantUserIds,
    checkMultipleAccounts = false
  ) {
    const userId = ownerId;
    const usersCalendars = await this.getCalendarByUserIds(accountId, ownerId);
    const calendarIds = usersCalendars.map((v) => v.id);
    return this.getEventsByCalendarId(
      accountId,
      calendarIds,
      { dtStart, dtEnd },
      participantUserIds,
      checkMultipleAccounts,
      userId
    );
  }

  async getEventsByCalendarId(
    accountId,
    calendarIds,
    { dtStart, dtEnd },
    participantUserIds,
    checkMultipleAccounts = false,
    userId
  ) {
    const participantsParam = participantUserIds
      ? participantUserIds.map((v) => `participant=${v}`).join("&")
      : null;
    const calendarIdParam = `calendarId=${JSON.stringify(
      Array.isArray(calendarIds) ? calendarIds : [calendarIds]
    )}`;
    const dtStartParam = `dtStart=${dtStart}`;
    const dtEndParam = `dtEnd=${dtEnd}`;
    const checkMultipleAccountsParam = `checkMultipleAccounts=${checkMultipleAccounts}`;
    const userIdParam = `userId=${JSON.stringify(
      Array.isArray(userId) ? userId : [userId]
    )}`;

    const eventsResponse = await this.http.get(
      `/accounts/${accountId}/calendarEvents?${calendarIdParam}&${dtStartParam}&${dtEndParam}&${checkMultipleAccountsParam}&${userIdParam}${
        participantsParam ? `&${participantsParam}` : ""
      } `
    );
    return eventsResponse.data;
  }

  async createCalendar(accountId, data) {
    const response = await this.http.post(
      `/accounts/${accountId}/calendars`,
      data
    );
    return response.data;
  }

  async deleteCalendarEventById(accountId, id) {
    const response = await this.http.delete(
      `/accounts/${accountId}/calendarEvents/${id}`
    );
    return response.data;
  }
}

const calendarApiInstance = new CalendarApi();
export default calendarApiInstance;
