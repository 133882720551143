import { NavbarContext } from "@contexts/Navbar";
import { useContext, useEffect, useMemo, useState } from "react";

export const useShowNavbar = () => {
  const { handleShowNavbar } = useContext(NavbarContext);
  useEffect(() => {
    handleShowNavbar(false);
    return () => {
      handleShowNavbar(true);
    };
  }, []);
};

export const useScreenSize = () => {
  const [isMdOrSmaller, setIsMdOrSmaller] = useState(window.innerWidth <= 768);
  const [isMdOrLarger, setIsMdOrLarger] = useState(window.innerWidth >= 768);

  const handleResize = () => {
    setIsMdOrSmaller(window.innerWidth < 768);
    setIsMdOrLarger(window.innerWidth >= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return useMemo(() => {
    const isMdOrSmallerScreen = isMdOrSmaller;
    const isMdOrLargerScreen = isMdOrLarger;
    return [{ isMdOrSmallerScreen, isMdOrLargerScreen }];
  }, [isMdOrSmaller, isMdOrLarger]);
};
