import { createContext, useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "./User";
import LocalStorageService from "@services/localStorage";
import { USER_ROLES } from "@utils/consts";
import {io} from 'socket.io-client';
import config from "@config/config";


export const AnonymousLiveChatContext = createContext({
    socketForAnonymousLiveChat: null,
    openedChatRoomId: undefined,
    setOpenedChatRoomId: () => {},
});

const AnonymousLiveChatProvider = ({children}) => {
    const {profile: currentProfile} = useContext(UserContext);
    const [openedChatRoomId, setOpenedChatRoomId] = useState();
    const socketForAnonymousLiveChat = useRef();

    useEffect(() => {
        if (!currentProfile
            || (currentProfile.type !== USER_ROLES.STUDENT_ROLE
                && currentProfile.type !== USER_ROLES.COUNSELLOR_ROLE)
            || (socketForAnonymousLiveChat.current && socketForAnonymousLiveChat.current.connected)) {
            return undefined;
        }

        const accessToken = LocalStorageService.getAccessToken();

        if (!accessToken) {
            return undefined;
        }

        socketForAnonymousLiveChat.current = io(config.liveChatSocketDomain, {
            transports: ['websocket'],
            path: config.liveChatSocketPath,
            query: {
                accessToken,
            },
        });

        return () => {
            if (socketForAnonymousLiveChat?.current && socketForAnonymousLiveChat.current.connected) {
                socketForAnonymousLiveChat.current.disconnect();
            }
        };
    }, [currentProfile?.id]);

    const value = {socketForAnonymousLiveChat, openedChatRoomId, setOpenedChatRoomId};

    return (
        <AnonymousLiveChatContext.Provider value={{...value}}>{children}</AnonymousLiveChatContext.Provider>
    );
};

export default AnonymousLiveChatProvider;