import { v4 as uuid } from "uuid";
import { sendNotify } from "@components/GlobalNotify/GlobalNotify";
import { Tooltip } from "primereact/tooltip";

export const setFieldForm = (method, setErrors, nameField, val) => {
  method(nameField, val, false);
  setErrors({});
};

export const checkErrorsBySubmit = (errors, fields) =>
  !!Object.keys(errors).length || Object.values(fields).includes("");

export const generateUUID = () => uuid();

export const colorByPercent = (value) => {
  if (value > 60) {
    return increaseBrightness("#FAC783", -value);
  }
  if (value > 40) {
    return increaseBrightness("#83B2F0", value);
  }
  if (value > 20) {
    return increaseBrightness("#94CB8C", value);
  }
  if (value > 10) {
    return increaseBrightness("#D1C7BB", -value);
  }
  return increaseBrightness("#E1E1E1", -value);
};

export const increaseBrightness = (hex, percent) => {
  // strip the leading # if it's there
  let color = hex.replace(/^\s*#|\s*$/g, "");

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (color.length === 3) {
    color = hex.replace(/(.)/g, "$1$1");
  }

  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  return `#${(0 | ((1 << 8) + r + ((256 - r) * percent) / 100))
    .toString(16)
    .substr(1)}${(0 | ((1 << 8) + g + ((256 - g) * percent) / 100))
    .toString(16)
    .substr(1)}${(0 | ((1 << 8) + b + ((256 - b) * percent) / 100))
    .toString(16)
    .substr(1)}`;
};

export const sumAllValuesInArr = (items, prop) =>
  items.reduce((a, b) => a + Number(b[prop] ?? 0), 0);

export const getMeanValue = (nums) =>
  nums.reduce((a, b) => a + b) / nums.length;

export const INTERVAL_TYPES = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  QUARTER: "quarter",
  YEAR: "year",
  ALL_TIME: "all_time",
};

export const formatByType = {
  [INTERVAL_TYPES.DAY]: "HH",
  [INTERVAL_TYPES.WEEK]: "EEE",
  [INTERVAL_TYPES.MONTH]: "dd",
  [INTERVAL_TYPES.QUARTER]: "MMM",
  [INTERVAL_TYPES.YEAR]: "MMM",
  [INTERVAL_TYPES.ALL_TIME]: "MMM yyyy",
};

export const handleNameLength = (name, trimValue = 15) => {
  if (name && name.length > trimValue) {
    return `${name.substring(0, trimValue)}...`;
  }
  return name;
};

export const getGenderDropdown = (t) => {
  const g = [
    {
      label: t("profile.edit.gender.male"),
      value: "Male",
    },
    {
      label: t("profile.edit.gender.female"),
      value: "Female",
    },
    {
      label: t("profile.edit.gender.other"),
      value: "Other",
    },
    {
      label: t("profile.edit.gender.private"),
      value: "Private",
    },
  ];
  return g;
};

// convert graphql response to formik error
export const getFieldErrors = (error) => {
  let err = {};
  if (Array.isArray(error?.graphQLErrors[0]?.extensions?.response?.message)) {
    error?.graphQLErrors[0]?.extensions.response.message.map((msg) => {
      err = { ...err, ...msg.error };
      return msg;
    });
  } else {
    sendNotify(error?.graphQLErrors[0]?.extensions?.response?.message, "error");
  }
  return err;
};

export const getErrors = (error) => {
  let err = [];
  if (Array.isArray(error?.graphQLErrors[0]?.extensions?.response?.message)) {
    error?.graphQLErrors[0]?.extensions.response.message.map((msg) => {
      err = [...err, ...Object.values(msg.error)];
    });
  }
  if (err.length === 0) {
    err = [error.message];
  }
  return err;
};

export const GetSortOrder = (prop, order) => {
  return function (a, b) {
    if (order === "ASC") {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
    } else if (order === "DESC") {
      if (a[prop] > b[prop]) {
        return -1;
      } else if (a[prop] < b[prop]) {
        return 1;
      }
    }
    return 0;
  };
};

export const getParentChildCategoryData = (
  categories,
  parent = null,
  language
) => {
  let result = [];
  let parentId = parent?.id ? parent.id : null;

  categories.forEach((element) => {
    if (element.parentCategory === parentId) {
      let el = element;
      el["children"] = getParentChildCategoryData(
        categories,
        element,
        language
      );
      result.push({
        label: language === "sv" ? element.nameSw : element.nameEng,
        key: element.id,
        children: element.children,
      });
    }
  });

  return result.sort(GetSortOrder("label", "ASC"));
};

export const removeDuplicateRecord = (allAccounts, key, accounts = []) => {
  allAccounts?.forEach((account) => {
    if (!accounts.find((x) => x[key] === account[key])) {
      accounts.push(account);
    }
  });
  return accounts;
};

export const covertStringLength = (value) => {
  if (value?.length > 20) {
    return value.substring(0, 30) + "...";
  }
  return value;
};

export const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const checkIsLink = (text) => {
  var urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, function (url) {
    return (
      '<a href="' +
      url +
      '" target="_blank" class="underline" style="color: #736fda;">' +
      url +
      "</a>"
    );
  });
};

export const getPathPattern = (params, pathname) => {
  // Create a new object from params
  let newParams = { ...params };

  // Delete the '*' key
  delete newParams["*"];
  const pathPattern = Object.entries(newParams).reduce((path, [key, value]) => {
    return path.replace(`/${value}`, `/:${key}`);
  }, pathname);
  return pathPattern;
};
