import {useTranslation} from 'react-i18next';

const NotFound = () => {
  const {t} = useTranslation();

  return (
    <div className="w-screen h-screen flex-1 flex flex-col items-center justify-center bg-main">
      <h1 className="text-dawn-gray text-9xl">{t('notFound.code')}</h1>
      <span className="text-dawn-gray text-3xl">{t('notFound.text')}</span>
    </div>
  );
};

export default NotFound;
