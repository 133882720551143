function SvgComponent() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="60" cy="60" r="60" fill="#FEE6CC" />
      <path
        d="M119.842 64.3875C117.595 95.4764 91.6617 120 60 120C26.8629 120 0 93.1375 0 60.0004C0 57.1376 0.20049 54.3217 0.588196 51.5659C3.43863 57.6013 12.5193 66.7065 18.6817 58.4199C18.9374 58.076 19.1993 57.7226 19.4674 57.3608C24.2239 50.9419 30.936 41.8838 40.0106 36.4605C49.5966 30.7314 62.7874 25.4695 81.7097 29.5393C93.3319 32.0389 114.824 41.3497 119.842 64.3875Z"
        fill="#FDB86D"
      />
      <circle cx="79.3171" cy="54.7305" r="2.63415" fill="#333331" />
      <circle cx="62.9269" cy="55.3165" r="2.63415" fill="#333331" />
    </svg>
  );
}

export default SvgComponent;
