import { useEffect, useMemo, useState, useContext, useRef } from "react";
import ProfileService from "@services/api/profile";
import { UserContext } from "@contexts/User";
import config from "@config/config";

export const useProfileList = (type, filterFn) => {
  const [profiles, setProfiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { profile } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (!profile) {
        return;
      }

      setIsLoading(true);
      let profilesResponse = await ProfileService.getProfilesByType(
        profile.accountId,
        type
      );

      if (filterFn && typeof filterFn === "function") {
        profilesResponse = filterFn(profilesResponse);
      }

      setProfiles(profilesResponse);
      setIsLoading(false);
    })();
  }, [profile, type]);

  return useMemo(() => {
    if (!profiles) return [[], { isLoading }];
    return [profiles, { isLoading }];
  }, [profiles, isLoading]);
};

export const useProfile = (profileId) => {
  const [profile, setProfile] = useState(null);
  const { profile: currentProfile } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (!currentProfile || !profileId) {
        return;
      }

      const profileResponse = await ProfileService.getProfileById(
        currentProfile.accountId,
        profileId
      );

      setProfile(profileResponse);
    })();
  }, [currentProfile, profileId]);

  return useMemo(() => {
    if (!profile) {
      return null;
    }

    return profile;
  }, [currentProfile, profileId, profile]);
};

export const useProfileImageUrl = (profilePictureName, accountId) =>
  useMemo(() => {
    if (!profilePictureName || !accountId) {
      return "";
    }

    return `${config.resourceApiUrl}/accounts/${accountId}/profileImage/${profilePictureName}`;
  }, [profilePictureName, accountId]);

export const useProfileUrl = (profilePictureName, accountId) => {
  if (!profilePictureName || !accountId) {
    return "";
  }

  return `${config.resourceApiUrl}/accounts/${accountId}/profileImage/${profilePictureName}`;
};

export const useProfileVideoUrl = (profileVideoName, accountId) =>
  useMemo(() => {
    if (!profileVideoName || !accountId) {
      return "";
    }

    return `${config.resourceApiUrl}/accounts/${accountId}/introductionVideo/${profileVideoName}`;
  });

export const useProfileImageUrlForOrg = (profilePictureName, accountId) =>
  useMemo(() => {
    if (!profilePictureName || !accountId) {
      return "";
    }

    return `${config.resourceApiUrl}/accounts/${accountId}/healthcare/${profilePictureName}`;
  }, [profilePictureName, accountId]);
