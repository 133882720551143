import { gql } from "@apollo/client";
import GraphQlService from "./graphql";

class AuthGraphql extends GraphQlService {
  async getOrganizationSubscriptions(accountId) {
    return this.client
      .query({
        query: gql`
          query GetOrganizationSubscriptions{
            getOrganizationSubscriptions(id: "${accountId}"){
              id,
              name,
              account{
                accountOrganizationSubscriptions{
                  subscriptionType{
                    id,
                    name,
                    code
                  }
                }
              }
            }
          }
        `,
      })
      .then((result) => result?.data?.getOrganizationSubscriptions);
  }

  async validateUser(data) {
    return this.client
      .query({
        query: gql`
          query ValidateUser{
            validateUser(
              validateUserInput: {
              email: "${data.email}",
              password: "${data.password}",
              confirmPassword: "${data.confirmPassword}"
            })
          }
        `,
      })
      .then((result) => result?.data?.validateUser);
  }

  async createSubscription(data) {
    return this.client
      .mutate({
        mutation: gql`
        mutation {
          createSubscription(
            createSubscription: {
              email: "${data.email}",
              password: "${data.password}",
              confirmPassword: "${data.confirmPassword}"
              name: "${data.name}"
              gender: "${data.gender}",
              birthday: "${data.birthday}"    ,
              accountId: "${data.accountId}",
              organizationId: "${data.organizationId}",
              organizationName: "${data.organizationName}",
              subscriptionType: "${data.subscriptionType}"
            }
          ){
            userId,
            accessToken,
            refreshToken
          }   
        }
        `,
      })
      .then((result) => result?.data?.createSubscription);
  }

  async getCurrentSubscriptionType() {
    return this.client
      .query({
        query: gql`
          query {
            getCurrentSubscriptionType {
              subscriptionType {
                id
                code
              }
              consumerSubscription {
                active
                activeUntil
              }
              subscriptionStatus
            }
          }
        `,
        fetchPolicy: "cache-first",
      })
      .then((result) => result?.data?.getCurrentSubscriptionType);
  }

  async validateRegistrationCode(code) {
    return this.client
      .query({
        query: gql`
          query {
            validateRegistrationCode(
              validateRegistrationCodeInput: {
              code: "${code}"
            }),
            {
              accountId
            }
          }
        `,
      })
      .then((result) => result?.data?.validateRegistrationCode);
  }

  changePassword(formData) {
    return this.client.mutate({
      mutation: gql`
        mutation {
          changePassword (
            ChangePasswordUserInput : {
              currentPassword: "${formData?.currentPassword}"
              password: "${formData?.newPassword}"
              confirmPassword: "${formData?.confirmPassword}"
            }
          )
        }
        `,
    });
  }

  getRelationshipInfo() {
    return this.client
      .query({
        query: gql`
          query {
            findChildrenAccounts {
              id
              name
            }
          }
        `,
      })
      .then((res) => res.data.findChildrenAccounts);
  }

  async addUserSessionByEmail(email) {
    return this.client
      .mutate({
        mutation: gql`
        mutation {
          addUserSessionByEmail(email: "${email}") 
        }
        `,
      })
      .then((result) => result?.data?.addUserSessionByEmail);
  }

  async checkSsoUser(email) {
    return this.client
      .mutate({
        mutation: gql`
      mutation{
        checkSsoUser(
          checkSsoUserInput:{
            email:"${email}"
          }){
              isRegistered
              redirectUrl
            }
          }`,
      })
      .then((result) => result?.data?.checkSsoUser);
  }

  async deactivateBulkStudentStatus(userId) {
    return this.client
      .mutate({
        mutation: gql`
        mutation {
          deactivateBulkStudentStatus(
            studentIds: """${userId}"""
          )
        }
        `,
      })
      .then((result) => result?.data?.deactivateBulkStudentStatus);
  }

  async getAllStatisticsForUsers(dtStart, dtEnd) {
    return this.client
      .query({
        query: gql`
                  query{
                    getAllStatisticsForUsers(dtStart: ${dtStart}, dtEnd: ${dtEnd}) {
                      inactive {
                        userId
                        accountId
                        email
                      }
                      registered {
                        userId
                        accountId
                        email
                      }
                      active {
                        userId
                        accountId
                        email
                      }
                    }
                  }
                `,
      })
      .then((result) => result?.data?.getAllStatisticsForUsers);
  }

  async usernameExists(username) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            usernameExists(username: """${username}""")
          }
        `,
      })
      .then((result) => result?.data?.usernameExists);
  }

  async registerStudent(username, password, accountId, customRolesId) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            registerStudent(
              registerUserInput: {
                userName: "${username}"
                password: "${password}"
                accountId: "${accountId}"
                customRolesId: "${customRolesId}"
              }
            ) {
              userId
              accessToken
              refreshToken
            }
          }
        `,
      })
      .then((result) => result?.data?.registerStudent);
  }

  async checkSsoUserWithUsername(username) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            checkSsoUserWithUsername(
              checkSsoUserWithUserNameInput: {
                userName: "${username}"
              }
            ) {
              isRegistered
            }
          }
        `,
      })
      .then((result) => result?.data?.checkSsoUserWithUsername);
  }

  async loginWithUsername(username, password) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            loginWithUsername(
              loginWithUsernameInput:{
                username: "${username}"
                password: "${password}"
              }) {
                refreshToken
		            accessToken
              }
            }`,
      })
      .then((result) => result?.data?.loginWithUsername);
  }
}

const authGraphqlInstance = new AuthGraphql();
export default authGraphqlInstance;
