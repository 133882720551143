import { generateUUID } from "@utils/helpers";
import { createContext, useEffect, useState } from "react";

export const DeviceContext = createContext({
    deviceId: undefined,
    setDeviceId: () => { },
});

const DeviceProvider = ({ children }) => {
    const [deviceId, setDeviceId] = useState(sessionStorage.getItem('deviceId'));
    useEffect(() => {
        if (!deviceId) {
            const newDeviceId = generateUUID();
            setDeviceId(newDeviceId);
            sessionStorage.setItem("deviceId", newDeviceId);
        }
    }, [deviceId]);

    const value = { deviceId, setDeviceId };

    return (
        <DeviceContext.Provider value={{ ...value }}>{children}</DeviceContext.Provider>
    );
};

export default DeviceProvider;