import { gql } from "@apollo/client";
import GraphQlService from "./graphql";

class umoGraphql extends GraphQlService {
  async getAccountIdAndTypeByUmoDetails(accountId, type) {
    return this.client
      .query({
        query: gql`
          query {
            getAccountIdAndTypeByUmoDetails(
              getAccountIdAndTypeByUmoDetailsInput: {
                accountId: ${accountId ? `"${accountId}"` : null} 
                type: ${type ? `"${type}"` : null}
              }
            ) {
              id
              accountId
              type
              image
              text
              address
              buttons
              links
              createdAt
              updatedAt
              createdBy
              updatedBy
              version
            }
          }
        `,
      })
      .then((result) => result?.data?.getAccountIdAndTypeByUmoDetails);
  }

  async createUmoDetailsDefault(umoDetails) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            createUmoDetailsDefault(
                createUmoDetailsInput: {
                    type: "${umoDetails.type}"
                    accountId: "${umoDetails.accountId}"
                    image: "${umoDetails.image}"
                }
            ) {
              id
            }
          }
        `,
      })
      .then((result) => result?.data?.createUmoDetailsDefault);
  }

  async getUmoDetailsByAccountId(accountId) {
    return this.client
      .query({
        query: gql`
          query {
            getUmoDetailsByAccountId(accountId: "${accountId}") {
              id
              accountId
              type
              image
              text
              address
              buttons
              links
              createdAt
              updatedAt
              createdBy
              updatedBy
              version
            }
          }
        `,
      })
      .then((result) => result?.data?.getUmoDetailsByAccountId);
  }

  async getCurrentUserUmoDetails() {
    return this.client
      .query({
        query: gql`
          query {
            getCurrentUserUmoDetails {
              id
              accountId
              type
              image
              text
              address
              buttons
              links
              createdAt
              updatedAt
              createdBy
              updatedBy
              version
            }
          }
        `,
      })
      .then((result) => result?.data?.getCurrentUserUmoDetails);
  }

  async updateUmoDetails(data) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            updateUmoDetails(
                updateUmoDetailsInput: {
                    id: "${data.id}"
                    accountId: "${data.accountId}"
                    image: ${data.image ? `"${data.image}"` : null}
                    text: ${
                      data.text ? `"""${JSON.stringify(data.text)}"""` : null
                    }
                    address: ${data.address ? `"${data.address}"` : null}
                    buttons: ${
                      data.buttons
                        ? `"""${JSON.stringify(data.buttons)}"""`
                        : null
                    }
                    links: ${
                      data.links ? `"""${JSON.stringify(data.links)}"""` : null
                    }
                }
            ) {
              id
              accountId
              type
              image
              text
              address
              buttons
              links
              createdAt
              updatedAt
              createdBy
              updatedBy
              version
              __typename
            }
          }
        `,
      })
      .then((result) => result?.data?.updateUmoDetails);
  }

  async getUmoDetailsById(id) {
    return this.client
      .query({
        query: gql`
          query {
            getUmoDetailsById(id: "${id}") {
              id
              accountId
              type
              image
              text
              address
              buttons
              links
              createdAt
              updatedAt
              createdBy
              updatedBy
              version
            }
          }
        `,
      })
      .then((result) => result?.data?.getUmoDetailsById);
  }

  async createChatChatQueueYouthsUmo(
    nickname,
    deviceId,
    accountId,
    parentAccountId
  ) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            createChatChatQueueYouthsUmo(
              createChatChatQueueYouthsUmoInput: {
                nickname: "${nickname}"
                deviceId: "${deviceId}"
                accountId: ${accountId ? `"${accountId}"` : null}
                parentAccountId: ${
                  parentAccountId ? `"${parentAccountId}"` : null
                }
              }
            ) {
              id
              nickname
              deviceId
              count
              createdAt
              updatedAt
              createdBy
              updatedBy
              version
            }
          }
        `,
      })
      .then((result) => result?.data?.createChatChatQueueYouthsUmo);
  }
}

const umoGraphqlService = new umoGraphql();
export default umoGraphqlService;
