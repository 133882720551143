import { gql } from "@apollo/client";
import GraphQlService from "./graphql";

class reportGraphql extends GraphQlService {
  async getReportAttributes(accountId) {
    return this.client
      .query({
        query: gql`
                query{
                  getReportAttributes(id:"${accountId}"){
                    attributeName
                    attributeValue
                  }
                }
                `,
      })
      .then((result) => result?.data?.getReportAttributes);
  }

  async getAllReportsAndNotesByCustomRoleId(startDate,endDate) {
    return this.client
      .mutate({
        mutation: gql`
                mutation{
                  getAllReportsAndNotesByCustomRoleId(
                    getReportAndNotesInput:{
                      startDate:"${startDate}",
                      endDate:"${endDate}",
                    }){
                    reports {
                      id
                      accountId
                      userId
                      reporterId
                      data
                      createdAt
                      updatedAt
                      createdBy
                      updatedBy
                      version
                      type
                      createdByUser {
                        accountPeople {
                          name
                        }
                      }
                      user {
                        accountPeople{
                          name
                          anonymousName
                          isAnonymous
                          accountPersonAttributes{
                            id
                            name
                            value
                          }
                        }
                      }
                    }
                    notes {
                      id
                      note
                      type
                      creatorName
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      user{
                        accountPeople{
                          name
                          isAnonymous
                          anonymousName
                          accountPersonAttributes{
                            id
                            name
                            value
                          }
                        }
                      }
                    }
                    responseDate
                    dataOver
                  }
                }
                `,
      })
      .then((result) => result?.data?.getAllReportsAndNotesByCustomRoleId);
  }

  async getReportByIdAndAccountId(id, accountId) {
    return this.client
      .query({
        query: gql`
                query{
                  getReportByIdAndAccountId(getReportByIdInput:{
                    id:"${id}",
                    accountId:"${accountId}",
                  }){
                    id
                    accountId
                    userId
                    reporterId
                    entityId
                    data
                    createdAt
                    updatedAt
                    createdBy
                    updatedBy
                    version
                    type
                    createdByUser {
                      accountPeople {
                        name
                      }
                    }
                    user {
                      accountPeople{
                        name
                        anonymousName
                        isAnonymous
                        accountPersonAttributes{
                          id
                          name
                          value
                        }
                      }
                    }
                  }
                }
                `,
      })
      .then((result) => result?.data?.getReportByIdAndAccountId);
  }

  async removeReportById(id) {
    return this.client
      .mutate({
        mutation: gql`
                mutation{
                  removeReportById(id: "${id}") {
                    response {
                      messages {
                        message
                      }
                    }
                  }
                }
                `,
      })
      .then((result) => result?.data?.removeReportById);
  }

  async getAllReportTopicsCategory(reportType) {
    return this.client
      .query({
        query: gql`
          {
            getAllReportTopicsCategory(
              getReportTopicsCategoryInput: { reportType: "${reportType}" }
            ) {
              id
              nameENG
              nameSW
              value
              reportTopics {
                id
                accountId
                nameENG
                nameSW
                value
                descriptionENG
                descriptionSW
              }
            }
          }
        `,
      })
      .then((result) => result?.data?.getAllReportTopicsCategory);
  }

  async createReportTopics(data) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            createReportTopics(
              createReportTopicsInput: {
                nameENG: "${data.name}"
                nameSW: "${data.name}"
                value: "${data.value}"
                descriptionENG: "${data.description}"
                descriptionSW: "${data.description}"
                accountId: "${data.accountId}"
                categoryId: "${data.categoryId}"
              }
            ) {
              id
            }
          }
          `,
      })
      .then((result) => result?.data?.createReportTopics);
  }
}
const reportGraphqlInstance = new reportGraphql();
export default reportGraphqlInstance;
