import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";

import { CustomModal } from "@components/Modals";
import { Divider } from "@components/Static";
import { ButtonGroup, Button, Slider, Calendar } from "@components/Form";
import {
  internalReportOptions,
  staticAttributes,
} from "@utils/internalReportData";
import { AppointmentReportContext } from "@contexts/AppointmentReport";
import { SuccessAnimation } from "@assets/lottie";

import styles from "./InternalReportModal.module.scss";
import { UserContext } from "@contexts/User";
import reportGraphqlInstance from "@services/api/report.graphql";
import { useOutside } from "@hooks/useOutside";
import { format } from "date-fns";
import * as Locale from "date-fns/locale";
import i18n from "i18n";
import CalendarIcon from "@assets/icons/Calendar";
import { AnimatePresence,motion } from "framer-motion";

const InternalReportModal = ({ isOpen }) => {
  const { t } = useTranslation();
  const { createInternalReport, internalReportItem, createInternalReportItem } =
    useContext(AppointmentReportContext);
  const { profile: currentProfile } = useContext(UserContext);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [selectedMeetingTypeOptions, setSelectedMeetingTypeOptions] = useState(
    []
  );
  const [attributes, setAttributes] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [meetingDuration, setMeetingDuration] = useState("0");
  const today = useMemo(() => new Date());
  const [date, setDate] = useState(today);
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);
  useOutside(calendarRef, setShowCalendar);

  const onChangeMeetingDuration = (event) => {
    setMeetingDuration(event.target.value);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedAttributes({});
      setSelectedMeetingTypeOptions([]);
      setMeetingDuration("0");
    } else {
      if (currentProfile) {
        reportGraphqlInstance
          .getReportAttributes(currentProfile.accountId)
          .then((res) => {
            setAttributes(res);
          });
      }
    }
  }, [isOpen]);

  const onSelectedMeetingTypeOptionsChange = (option) => {
    let found = false;
    const newOptions = selectedMeetingTypeOptions
      .map((selectedMeetingTypeOption) => {
        if (selectedMeetingTypeOption.value === option.value) {
          found = true;
          return null;
        }
      })
      .filter((selectedMeetingTypeOption) => !!selectedMeetingTypeOption);

    if (!found) {
      newOptions.push(option);
    }
    setSelectedMeetingTypeOptions(newOptions);
  };

  const onSelectedAttributesChange = (option, x) => {
    const selectedItem = JSON.parse(JSON.stringify(selectedAttributes));
    if (!selectedItem[x.attributeName.value]) {
      selectedItem[x.attributeName.value] = [];
    }
    let found = false;
    const newOptions = selectedItem[x.attributeName.value]
      .map((selectedOption) => {
        if (selectedOption.value === option.value) {
          found = true;
          return null;
        }
        return selectedOption;
      })
      .filter((selectedOption) => !!selectedOption);

    if (!found) {
      newOptions.push(option);
    }
    selectedItem[x.attributeName.value] = newOptions;
    setSelectedAttributes(selectedItem);
  };

  const saveAllReport = async () => {
    let attributesData = {};
    for (const [key, value] of Object.entries(selectedAttributes)) {
      Object.assign(attributesData, {
        [t(key)]: value.map((v) => t(v.value)),
      });
    }
    setIsSaving(true);
    try {
      await createInternalReport({
        data: {
          attributes: attributesData,
          meetingDuration: meetingDuration,
          meetingType: selectedMeetingTypeOptions.map((option) => option.value),
          createdAt:new Date(date).getTime(),
          updatedAt:new Date(date).getTime()
        },
      });
      setIsSaving(false);
      setSelectedAttributes({});
      setSelectedMeetingTypeOptions([]);
      setMeetingDuration("0");
      setDate(today);
      setIsSaved(true);
    } catch (err) {
      setIsSaving(false);
    }
  };

  const closeSavedModal = () => {
    createInternalReportItem(null);
    setIsSaved(false);
  };
  const closeModal = () => {
    if (internalReportItem?.isManualReport) {
      createInternalReportItem(null);
      setSelectedAttributes({});
      setSelectedMeetingTypeOptions([]);
      setMeetingDuration("0");
      setDate(today);
    } else {
      setIsSaved(false);
    }
  };

  const onChangeDate = (newDate) => {
    setDate(newDate);
  };

  const renderSteps = () => {
    if (isSaved) {
      return (
        <div className="flex flex-col justify-center items-center">
          <div className="w-96 h-56">
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: SuccessAnimation,
                rendererSettings: {
                  viewBoxSize: "90 50 760 500",
                },
              }}
              ariaRole="none"
            />
          </div>
          <h1 className="font-bold-poppins text-3xl leading-title text-dawn-gray tracking-tight mb-2">
            {t("reports.participationThanks.title")}
          </h1>
          <span className="text-base text-cloudy-gray max-w-xs text-center mb-12">
            {t("reports.participationThanks.details")}
          </span>
          <Button
            variant="edit"
            className="rounded-full py-4 px-24"
            onClick={closeSavedModal}
            isLoading={isSaving}
          >
            <span className="text-white">
              {t("reports.participationThanks.close")}
            </span>
          </Button>
        </div>
      );
    }

    return (
      <div className="flex flex-col">
        <div className="h-28 px-12 flex flex-row items-center">
          <span className="font-bold-poppins text-3xl text-dawn-gray tracking-tight m-0">
            {t("reports.title")}
          </span>
        </div>
        <Divider />
        <div
          className="flex flex-col px-12"
          style={{ maxHeight: "calc(100vh - 350px)", overflowY: "auto" }}
        >
          <div className="pt-9">
            <span className="text-dawn-gray text-xl font-bold">
              {t("reports.selectDate")}
            </span>
            <div className="mt-2">
              <div className="flex pt-3 flex-row relative">
                <Button
                  onClick={() => setShowCalendar(!showCalendar)}
                  className={clsx(
                    "justify-center items-center bg-silver-light rounded py-5 px-4 border border-solid border-transparent",
                    showCalendar && "pointer-events-none"
                  )}
                  variant="custom"
                >
                  <span className="text-dawn-gray mr-4">
                    {format(date, "EEEE, MMMM d", {
                      locale: Locale[i18n.language],
                    })}
                  </span>
                  <CalendarIcon width={22} height={20} />
                </Button>
                <AnimatePresence>
                  {showCalendar && (
                    <motion.div
                      ref={calendarRef}
                      className={clsx(
                        "absolute left-60 rounded-md z-10 flex flex-row bg-milk",
                        styles.calendarShadow
                      )}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, translateY: "0.625rem" }}
                      exit={{ opacity: 0, translateY: "0.625rem" }}
                    >
                      <Calendar
                        withoutShadow
                        value={date}
                        maxDate={today}
                        onChange={onChangeDate}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
          <div className="pt-9">
            <span className="text-dawn-gray text-xl font-bold">
              {t("counsellorReport.internalReport.selectTypes")}
            </span>
            <div className="mt-9">
              <ButtonGroup
                className="flex flex-row flex-wrap gap-2"
                variant="report"
                variantSelected="reportSelected"
                options={internalReportOptions}
                selectedOptions={selectedMeetingTypeOptions}
                onChange={onSelectedMeetingTypeOptionsChange}
              />
            </div>
          </div>
          <div className="mt-11 mb-16">
            <span className="text-dawn-gray text-xl font-bold">
              {t("counsellorReport.internalReport.selectFilter")}
            </span>
            {staticAttributes?.length
              ? staticAttributes.map((x, index) => {
                  const result =
                    selectedAttributes[x.attributeName.value] || [];
                  return (
                    <React.Fragment key={`static-attribute-${index}`}>
                      <span className="text-dawn-gray text-xl block p-2 mt-2">
                        {t(x.attributeName.label)}
                      </span>
                      <ButtonGroup
                        className="flex flex-row flex-wrap gap-2"
                        variant="report"
                        variantSelected="reportSelected"
                        options={x.attributeValue.map((y) => ({
                          label: y.label,
                          value: y.value,
                        }))}
                        selectedOptions={result}
                        onChange={(e) => {
                          onSelectedAttributesChange(e, x);
                        }}
                      />
                    </React.Fragment>
                  );
                })
              : null}
            {attributes?.length
              ? attributes.map((x, index) => {
                  const result = selectedAttributes[x.attributeName] || [];
                  return (
                    x.attributeValue?.length && (
                      <React.Fragment key={`attribute-${index}`}>
                        <span className="text-dawn-gray text-xl block p-2 mt-2">
                          {x.attributeName}
                        </span>
                        <ButtonGroup
                          className="flex flex-row flex-wrap gap-2"
                          variant="report"
                          variantSelected="reportSelected"
                          options={x.attributeValue.map((y) => ({
                            label: y,
                            value: y,
                          }))}
                          selectedOptions={result}
                          onChange={(e) => {
                            onSelectedAttributesChange(e, {
                              ...x,
                              attributeName: {
                                label: x.attributeName,
                                value: x.attributeName,
                              },
                            });
                          }}
                        />
                      </React.Fragment>
                    )
                  );
                })
              : null}
          </div>

          <div>
            <span className="text-dawn-gray text-xl font-bold">
              {t("counsellorReport.internalReport.meetingDuration")}
            </span>
            <div className="mt-3 flex justify-center items-center">
              <Slider
                className="w-full"
                value={meetingDuration}
                withoutBackGroundColor={true}
                max={8}
                onChange={onChangeMeetingDuration}
              />
            </div>
          </div>
        </div>
        <div className="absolute left-0 bottom-0 w-full py-3 px-12 z-10 shadow-top flex flex-row justify-end items-center">
          <Button
            variant="default"
            className="rounded-full w-44 py-4 border-transparent border-solid border"
            type="submit"
            isLoading={isSaving}
            isDisabled={!selectedMeetingTypeOptions?.length}
            onClick={saveAllReport}
          >
            <span className="text-white">{t("common.save")}</span>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <CustomModal
      openModal={isOpen || isSaved}
      setOpenModal={closeModal}
      hideCloseButton={!isSaved && !internalReportItem?.isManualReport}
      className={clsx(
        "bg-milk rounded-xl",
        styles.reportModal,
        isSaved ? "p-8" : "pb-28"
      )}
    >
      {renderSteps()}
    </CustomModal>
  );
};

export default InternalReportModal;
