import * as React from 'react';
import PropTypes from 'prop-types';

const HomeIcon = ({ fill, ...props }) => {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.728 5.062l-5-4.021a4.781 4.781 0 00-5.956 0l-5 4.021A4.722 4.722 0 000 8.759v5.994A4.754 4.754 0 004.75 19.5H7.5v-4.747a2.251 2.251 0 014.5 0V19.5h2.75a4.753 4.753 0 004.75-4.747V8.76a4.722 4.722 0 00-1.772-3.697zM18 14.753A3.252 3.252 0 0114.75 18H13.5v-3.247a3.753 3.753 0 00-3.75-3.747A3.753 3.753 0 006 14.753V18H4.75a3.252 3.252 0 01-3.25-3.247V8.76c0-.987.441-1.908 1.211-2.528l5-4.021a3.274 3.274 0 014.077 0l5 4.021c.77.62 1.211 1.54 1.211 2.528v5.994H18z"
                fill={fill}
            />
        </svg>
    );
};

HomeIcon.propTypes = {
    fill: PropTypes.string,
};

HomeIcon.defaultProps = {
    fill: '#585550',
};

export default HomeIcon;
