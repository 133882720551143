import loadable from "@loadable/component";
import {
  loadableAuthParams,
  loadableRootParams,
} from "@components/Skeleton/loadableParams";

const Counsellor = {
  UsersAccountSelection: loadable(
    () => import("./UsersAccountSelection"),
    loadableAuthParams
  ),
  Appointments: loadable(() => import("./Appointments"), loadableRootParams),
  AppointmentDetail: loadable(
    () => import("./AppointmentDetail"),
    loadableRootParams
  ),
  Messages: loadable(() => import("./Messages"), loadableRootParams),
  Students: loadable(() => import("./Students"), loadableRootParams),
  Reports: loadable(() => import("./Reports"), loadableRootParams),
  StudentNotesList: loadable(
    () => import("./Notes/NotesList/NotesList"),
    loadableRootParams
  ),
  StudentStatistics: loadable(
    () => import("./Statistics/Statistics"),
    loadableRootParams
  ),
  StudentNotesAdd: loadable(
    () => import("./Notes/NoteAdd/NoteAdd"),
    loadableRootParams
  ),
  StudentNotesItem: loadable(
    () => import("./Notes/NoteItem/NoteOverview"),
    loadableRootParams
  ),
  Booking: loadable(() => import("./AppointmentBooking"), loadableRootParams),
  FreeTime: loadable(() => import("./FreeTime"), loadableRootParams),
  Config: loadable(
    () => import("@components/SurveyAndCheckIns"),
    loadableRootParams
  ),
  ConfigCreate: loadable(
    () => import("@components/SurveyAndCheckIns/Create/CreateConfig"),
    loadableRootParams
  ),
  CreateOwnTempLate: loadable(
    () => import("@components/SurveyAndCheckIns/Create/CreateOwnTemplate"),
    loadableRootParams
  ),
  Statistic: loadable(
    () => import("@components/Statistics/Statistics"),
    loadableRootParams
  ),
  StudentReportItem: loadable(
    () => import("./Reports/Notes/NoteItem/ReportOverview"),
    loadableRootParams
  ),
  AnonymousChat: loadable(() => import("./AnonymousChat"), loadableRootParams),
  Organization: loadable(() => import("./Organization"), loadableRootParams),
};

export default Counsellor;
