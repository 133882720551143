import loadable from "@loadable/component";
import { loadableRootParams } from "@components/Skeleton/loadableParams";

const Student = {
  Appointments: loadable(() => import("./Appointments"), loadableRootParams),
  AppointmentDetail: loadable(
    () => import("./AppointmentDetail"),
    loadableRootParams
  ),
  CounsellorsList: loadable(() => import("./Counsellors"), loadableRootParams),
  Messages: loadable(() => import("./Messages"), loadableRootParams),
  Booking: loadable(() => import("./AppointmentBooking"), loadableRootParams),
  Questions: loadable(() => import("./Questions"), loadableRootParams),
  SurveyAndCheckIns: loadable(
    () => import("./SurveyAndCheckIns"),
    loadableRootParams
  ),
  Home: loadable(() => import("./Home"), loadableRootParams),
  UserAnonymousChat: loadable(
    () => import("../Auth/StorSthlm/AnonymousChat"),
    loadableRootParams
  ),
  UserAnonymousChatWait: loadable(
    () => import("../Auth/StorSthlm/AnonymousChat/AnonymousChatWaitPage"),
    loadableRootParams
  ),
};

export default Student;
