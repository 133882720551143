import {
  APPOINTMENT_DETAIL,
  APPOINTMENTS,
  COUNSELLORS,
  MESSAGES,
  APPOINTMENTS_BOOKING,
  QUESTIONS,
  SURVEY_AND_CHECK_IN,
  ROOT,
  STUDENT_ANONYMOUS_CHAT,
  STUDENT_ANONYMOUS_CHAT_STEPS,
} from "@router/consts";
import Student from "@pages/Student";
import { Calendar, HomeIcon } from "@assets/icons";
import Chat from "@components/Navbar/ChatIcon";
import { ability } from "ability/ability";
import { USER_ROLES, CUSTOM_USER_ROLES } from "@utils/consts";
import AppointmentIcon from "@components/Navbar/AppointmentIcon";
import TemplateIcon from "@assets/icons/Template";

export const STUDENT_MENU_ROUTES = () => {
  let studentMenuRoutes = [];

  studentMenuRoutes.push({
    name: "home",
    path: ROOT,
    icon: HomeIcon,
    exact: true,
    isMenu: true,
    element: <Student.Home />,
  });

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.STUDENT_ROLE].APPOINTMENTS,
      USER_ROLES.STUDENT_ROLE
    )
  ) {
    studentMenuRoutes.push({
      name: "appointments",
      path: APPOINTMENTS,
      icon: AppointmentIcon,
      exact: true,
      isMenu: true,
      element: <Student.Appointments />,
    });
  }

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.STUDENT_ROLE].CHATS,
      USER_ROLES.STUDENT_ROLE
    )
  ) {
    studentMenuRoutes.push({
      name: "messages",
      exact: true,
      isMenu: true,
      icon: Chat,
      path: MESSAGES,
      element: <Student.Messages />,
    });
  }

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.STUDENT_ROLE].SURVEY_AND_CHECK_IN_PAGE,
      USER_ROLES.STUDENT_ROLE
    )
  ) {
    studentMenuRoutes.push({
      name: "checkInsAndSurveys",
      exact: true,
      isMenu: true,
      icon: TemplateIcon,
      path: SURVEY_AND_CHECK_IN,
      element: <Student.SurveyAndCheckIns />,
    });
  }

  return studentMenuRoutes;
};

export const STUDENT_ROUTES = () => {
  let studentRoutes = STUDENT_MENU_ROUTES();

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.STUDENT_ROLE].APPOINTMENTS,
      USER_ROLES.STUDENT_ROLE
    )
  ) {
    studentRoutes.push({
      name: "appointmentBooking",
      exact: true,
      path: APPOINTMENTS_BOOKING,
      element: <Student.Booking />,
    });

    studentRoutes.push({
      name: "appointmentDetail",
      path: APPOINTMENT_DETAIL,
      element: <Student.AppointmentDetail />,
    });
  }

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.STUDENT_ROLE].CHATS,
      USER_ROLES.STUDENT_ROLE
    )
  ) {
    studentRoutes.push({
      name: "messages",
      path: MESSAGES,
      exact: true,
      element: <Student.Messages />,
    });
  }

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.STUDENT_ROLE].COUNSELLOR_PROFILE,
      USER_ROLES.STUDENT_ROLE
    )
  ) {
    studentRoutes.push({
      name: "counsellors",
      path: COUNSELLORS,
      exact: true,
      element: <Student.CounsellorsList />,
    });
  }

  studentRoutes.push({
    name: "surveyQuestions",
    path: QUESTIONS,
    exact: true,
    element: <Student.Questions />,
  });

  studentRoutes.push({
    name: "userAnonymousChat",
    path: STUDENT_ANONYMOUS_CHAT,
    exact: true,
    element: <Student.UserAnonymousChat />,
  });

  studentRoutes.push({
    name: "userAnonymousChatWait",
    path: STUDENT_ANONYMOUS_CHAT_STEPS.ANONYMOUS_CHAT_WAIT,
    element: <Student.UserAnonymousChatWait />,
  });

  return studentRoutes;
};
