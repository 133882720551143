import { useCallback, useRef, useState } from "react";
import { useOutside } from "@hooks/useOutside";
import { Element, scroller } from "react-scroll/modules";
import { Check, SmallArrow } from "@assets/icons";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import useDeferredRenderEffect from "@hooks/useDifferredRenderEffects";
import styles from "./Dropdown.module.scss";
import ProfilePicture from "@components/ProfilePicture";
import { useProfileImageUrl } from "@hooks/profile";
import { handleNameLength } from "@utils/helpers";
import { useTranslation } from "react-i18next";

const Dropdown = ({
  onChange,
  classSelect,
  label,
  options,
  scrollID = "default",
  error,
  value,
  className,
  placeholder,
  isShowProfilePicture = false,
  showMoreDescription = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutside(wrapperRef, setIsOpen);
  const { t } = useTranslation();

  const profilePictureUrl = useProfileImageUrl(
    value?.profilePictureName,
    value?.accountId
  );

  const scroll = (fieldName) => {
    scroller.scrollTo(`value-${fieldName}`, {
      duration: 300,
      smooth: true,
      containerId: `dropdown-${scrollID}`,
      offset: -5,
    });
  };

  const viewLabel = useCallback(() => {
    if (value || value === 0) {
      return (
        <>
          {isShowProfilePicture ? (
            <div className="flex w-full items-center justify-between ">
              {handleProfilePicture(value, profilePictureUrl)}
              <div className="w-3/4 pl-2">
                <span className="text-dawn-gray font-medium-poppins">
                  {handleNameLength(
                    options.find((v) => v.value?.id === value?.id)?.label,
                    18
                  )}
                </span>
                {showMoreDescription && (
                  <h4 className="text-sonic-silver">
                    ({t("common.and")}&nbsp;{options?.length - 1}&nbsp;
                    {t("common.more")})
                  </h4>
                )}
              </div>
            </div>
          ) : (
            <span className="text-dawn-gray">
              {options.find((v) => v.value === value)?.label}
            </span>
          )}
        </>
      );
    }
    if (placeholder) {
      return <span className="text-gray-light">{placeholder}</span>;
    }
    return <span className="text-dawn-gray"> - </span>;
  }, [value, placeholder, options]);

  useDeferredRenderEffect(() => {
    if (value && isOpen) {
      scroll(value);
    }
  }, [value, isOpen]);

  const handleProfilePicture = (value, profilePictureUrl = null) => {
    if (value) {
      return (
        <div className="w-1/4 flex justify-center">
          <ProfilePicture
            profile={value}
            profilePictureUrl={profilePictureUrl}
            alt="profile"
            profilePictureStyle={{ className: "w-12 h-12" }}
          />
        </div>
      );
    }
    return;
  };

  const onClick = (option) => {
    if (!option?.value) {
      return;
    }

    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className={clsx("relative", className)}>
      {!!label && <p className="text-cloudy-gray pl-4 mb-3.5">{label}</p>}
      <div
        className={clsx(
          "w-full rounded border border-transparent border-solid flex items-center justify-between shadow-redShadow cursor-pointer",
          isShowProfilePicture ? "p-4" : "px-4",
          classSelect,
          isOpen ? "pointer-events-none" : "",
          error ? "border-light-red" : ""
        )}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {viewLabel()}
        <SmallArrow className="transform rotate-90 stroke-current text-dawn-gray" />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={clsx(
              "absolute z-20 flex flex-1 flex-row w-full rounded-sm top-full shadow",
              styles.list
            )}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, translateY: "0.625rem" }}
            exit={{ opacity: 0, translateY: "1.25rem" }}
          >
            <div
              className={clsx(
                "flex flex-col flex-1 bg-white p-1",
                styles.hideWithScrollbar
              )}
              ref={wrapperRef}
              id={`dropdown-${scrollID}`}
            >
              {options.map((item) => (
                <Element
                  key={isShowProfilePicture ? item.value?.id : item.value}
                  name={`value-${item.value}`}
                  className={clsx(
                    "px-2 text-dawn-gray relative rounded-md py-2 cursor-pointer flex flex-row justify-between items-center",
                    (
                      isShowProfilePicture
                        ? value?.id === item.value?.id
                        : value === item.value
                    )
                      ? "bg-pampas font-medium-poppins"
                      : ""
                  )}
                  onClick={() => onClick(item)}
                >
                  {isShowProfilePicture ? (
                    <div className="flex w-full justify-between items-center ">
                      {handleProfilePicture(item?.value)}
                      <div className="w-3/4 pl-2">{item.label}</div>
                    </div>
                  ) : (
                    item.label
                  )}
                  {value === item.value ? <Check className="mb-1" /> : <></>}
                </Element>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default Dropdown;
