import { useState, useEffect, useRef, useContext } from "react";
import clsx from "clsx";

import { Chat } from "@assets/icons";
import { useChatList, useGlobalChatList } from "@hooks/chats";
import messageSound from "../../../assets/sounds/Message.mp3";
import { ChatContext } from "@contexts/Messages";
import { GlobalChatContext } from "@contexts/GlobalMessages";
import { UserContext } from "@contexts/User";

const ChatIcon = ({ isActiveTab, className, ...props }) => {
  const isMounted = useRef(false);
  const soundRef = useRef(new Audio(messageSound));
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(null);
  const { profile } = useContext(UserContext);
  const [chatList, { isLoading }] = useChatList({
    isPopulateChatParticipants: true,
    isPopulateLastMessage: true,
    isPopulateChatParticipantProfiles: false,
    allowReadMessageEventEmitting: false,
    isCacheEnabled: false,
  });

  const [globalChatList, { isLoading: isGlobalLoading }] = useGlobalChatList({
    isPopulateChatParticipants: true,
    isPopulateLastMessage: true,
    isPopulateChatParticipantProfiles: true,
    allowReadMessageEventEmitting: true,
    isCacheEnabled: true,
  });
  const { socket } = useContext(ChatContext);
  const { socketForGlobalMessage } = useContext(GlobalChatContext);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    const globalChatListResult =
      !isGlobalLoading &&
      globalChatList
        ?.filter((chatRoom) => chatRoom?.isSelfParticipant)
        .map((chatRoom) => chatRoom.unreadMessageCount)
        .reduce((prev, cur) => prev + cur, 0);
    const chatListResult =
      !isLoading &&
      chatList
        ?.filter((chatRoom) => chatRoom?.isSelfParticipant)
        .map((chatRoom) => chatRoom.unreadMessageCount)
        .reduce((prev, cur) => prev + cur, 0);

    if (
      unreadMessagesCount !== null &&
      globalChatListResult + chatListResult > unreadMessagesCount
    ) {
      soundRef.current?.play().catch(() => {});
    }

    setUnreadMessagesCount(globalChatListResult + chatListResult);
  }, [chatList, globalChatList, socket, socketForGlobalMessage]);

  return (
    <div className="relative">
      {unreadMessagesCount && unreadMessagesCount > 0 ? (
        <div
          className={clsx(
            "absolute -right-2/5 -top-35% h-6 w-6 bg-primary rounded-full flex items-center justify-center border-1.5",
            isActiveTab ? "border-pampas" : "border-main"
          )}
        >
          <span className="text-white font-light-poppins font-black text-sm leading-5">
            {unreadMessagesCount}
          </span>
        </div>
      ) : null}
      <Chat className={className} {...props} />
    </div>
  );
};

export default ChatIcon;
