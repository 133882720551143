export const USER_ROLES = {
  PRINCIPAL_ROLE: "principal",
  COUNSELLOR_ROLE: "counsellor",
  STUDENT_ROLE: "student",
  ADMIN_ROLE: "admin",
  ALLBRY_COUNSELLOR_ROLE: "allbryCounsellor",
};

export const CUSTOM_USER_ROLES = {
  [USER_ROLES.PRINCIPAL_ROLE]: {
    USER_MANAGEMENT: "userManagement",
    DASHBOARD: "dashboard",
    ORGANIZATION_SETTINGS: "organizationSettings",
    SURVEY_CONFIG: "surveyConfig",
  },
  [USER_ROLES.COUNSELLOR_ROLE]: {
    APPOINTMENTS: "appointments",
    DASHBOARD: "dashboard",
    STUDENT_PROFILE: "studentProfile",
    CHATS: "chats",
    STUDENT_LIST: "studentList",
    NOTES: "notes",
    REPORTS: "reports",
    VIDEO: "video",
    INTERNAL_REPORT: "internalReport",
    ANONYMOUS_QUICK_REPORT: "anonymousQuickReport",
    SURVEY_CONFIG: "surveyConfig",
    STATISTICS: "statistics",
    ANONYMOUS_LIVE_CHAT: "anonymousLiveChat",
    ORGANIZATION_SETTINGS: "organizationSettings",
  },
  [USER_ROLES.STUDENT_ROLE]: {
    APPOINTMENTS: "appointments",
    CHATS: "chats",
    COUNSELLOR_PROFILE: "counsellorProfile",
    VIDEO: "video",
    SURVEY_AND_CHECK_IN_PAGE: "surveyAndCheckInPage",
  },
};

export const SURVEY_STATUSES = {
  STUDENT_SURVEY: "studentSurvey",
  STUDENT_GENDER: "studentGender",
};

export const AFTER_SURVEY_STEPS = {
  FIRST_STEP: "appointments",
  SECOND_STEP: "messages",
};

export const CHAT_MESSAGE_TYPES = {
  TEXT: "text",
  ATTACHMENT: "attachment",
  TEXT_ATTACHMENT: "textAttachment",
  AKK_IMAGE: "image",
  END: "end",
  ARCHIVED: "archived",
  VIDEO_CALL: "videoCall",
};

export const millisecondsInHour = 3600000;

export const NOTE_TYPES = {
  MANUAL: "manual",
  APPOINTMENT: "appointment",
  META: "meta",
};

export const GENDERS = {
  MALE: "Male",
  FEMALE: "Female",
  OTHER: "Other",
};

export const USER_STATUSES = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  DEACTIVATED: "deactivated",
};

export const USER_STATUS = {
  TRUE: true,
  FALSE: false,
};

export const SURVEY_TIMER = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
};

export const ORGANIZATION_STATUSES = {
  ACTIVE: "active",
  DEACTIVATED: "deactivated",
};

export const FREE_TIME_REPEAT_TYPES = {
  NONE: "none",
  EVERYDAY: "everyday",
  WEEKLY: "weekly",
  EVERY_WEEKDAY: "every_weekday",
};

export const MEDIA_FONT_SIZE = {
  XS: 12,
  SM: 14,
  BASE: 16,
};

export const MEDIA_SCREEN = {
  SM: 1280,
  LG: 1601,
};

export const NOTIFIACTION_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  UPCOMING: "Upcoming",
  SENT: "Sent",
};

export const SURVEY_TEMPLATE_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const SURVEY_CONFIG_RUN_ONCE = {
  ACTIVE: "true",
  INACTIVE: "false",
};

export const SURVEY_STATISTICS_STATUS = {
  ACTIVE: "active",
  NEW: "new",
  COMPLETE: "complete",
};

export const SURVEY_QUESTION_TYPES = {
  FILLING: "filling",
  SINGLE_SELECT: "single-select",
  RANGE_SLIDER: "rang-slider",
  RANKING: "ranking",
  CHECKBOX: "checkbox",
  TAG_BOX: "tagbox",
  BOOLEAN: "boolean",
  RATING: "rating",
  RADIO_GROUP: "radiogroup",
  DROPDOWN: "dropdown",
  IMAGE: "images",
  TEXT: "text",
};

export const BG_COLOR_COUNSELLING_POPULAR_CHART = [
  "#E9967A",
  "#B8860B",
  "#8FBC8F",
  "#FF7F50",
  "#BDB76B",
  "#556B2F",
  "#FFA07A",
  "#9ACD32",
  "#DAA520",
  "#FA8072",
  "#CD5C5C",
  "#EEE8AA",
  "#F0E68C",
  "#F0E68C",
  "#808000",
  "#CF7602",
  "#474645",
  "#8B4513",
  "#8B4513",
  "#808000",
  "#CF7602",
  "#E9967A",
  "#B8860B",
  "#8FBC8F",
  "#FF7F50",
  "#BDB76B",
  "#556B2F",
  "#FFA07A",
  "#9ACD32",
  "#DAA520",
  "#FA8072",
  "#CD5C5C",
  "#EEE8AA",
  "#2F4F4F",
  "#800000",
];

export const BROADCAST_MESSAGES_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const REPORT_TOPICS_CATEGORY_TYPE = {
  STUDENT: "STUDENT",
  COUNSELLOR: "COUNSELLOR",
};

export const STUDENT_COLORS_FLAG = [
  {
    color: "3green",
    value: "#97d3b9",
  },
  {
    color: "2yellow",
    value: "#fdce96",
  },
  {
    color: "1red",
    value: "#f7a6a4",
  },
];

// For broadcast chat modules super admin.
export const SUPER_ADMIN_SENDER_NAME = "Allbry";

export const UMO_DETAILS_TYPES = {
  MAIN_START: "MAIN_START",
  YOUTH_START: "YOUTH_START",
  CLASS_START: "CLASS_START",
  UMO: "UMO",
};
