import { SkeletonTheme } from 'react-loading-skeleton';
import { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { AdminContext } from '@admin/contexts/AdminContext';
import { useAdminRoutes } from '@admin/hooks/useAdminRoutes';
import useResize from '@hooks/useResize';
import { MEDIA_FONT_SIZE, MEDIA_SCREEN } from '@utils/consts';
import GlobalNotify from '@components/GlobalNotify/GlobalNotify';
import { useRoutes } from 'react-router-dom/dist';

const Admin = () => {
  const { user, isMainLoading, setHtmlFontSize } = useContext(AdminContext);
  const routers = useAdminRoutes(user, isMainLoading);

  let element = routers && useRoutes(routers);
  const { windowWidth } = useResize();

  const setFontSize = (val) => {
    document.documentElement.style.fontSize = `${val}px`;
    setHtmlFontSize(val);
  };

  useEffect(() => {
    if (windowWidth < MEDIA_SCREEN.SM) {
      setFontSize(MEDIA_FONT_SIZE.XS);
    } else if (windowWidth < MEDIA_SCREEN.LG) {
      setFontSize(MEDIA_FONT_SIZE.SM);
    } else {
      setFontSize(MEDIA_FONT_SIZE.BASE);
    }
  }, [windowWidth]);

  /*
  No use mobile screen for admin
  if (isMobile && window.innerWidth < 1100) {
    return (<Root.MobileView isLandscapeAvailable={window.innerWidth >= 1100} />);
  }
  */

  return (
    <>
      <GlobalNotify />
      <SkeletonTheme
        highlightColor="var(--colors-milk)"
        color="#fff"
      >
        {element}
      </SkeletonTheme>
    </>
  );
};
export default Admin;
