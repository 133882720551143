/* eslint-disable no-case-declarations */
import {
  createContext, useEffect, useState, useCallback, useContext, useRef,
} from 'react';

import LocalStorageService from '@services/localStorage';
import ReportService from '@services/api/report';
import VideoService from '@services/api/video';
import ChatRoomService from '@services/api/chat';
import {USER_ROLES} from '@utils/consts';
import {UserContext} from './User';
import {io} from 'socket.io-client';
import config from '@config/config';

export const AppointmentReportContext = createContext({
  appointmentReportItem: null,
  internalReportItem:null,
  createReport: async () => {},
  createAppointmentReportItem: () => {},
  createAppointmentReportCounsellorId: () => {},
  createInternalReportItem:()=>{},
  createInternalReport:async ()=>{},
  appointmentSocket: null,
});

const AppointmentReportProvider = ({children}) => {
  const {profile: currentProfile} = useContext(UserContext);
  const [appointmentReportItem, setAppointmentReportItem] = useState();
  const [internalReportItem, setInternalReportItem] = useState();
  const [appointmentSocket, setAppointmentSocket] = useState();

  const socket = useRef();
  useEffect(() => {
    if (!currentProfile
      || (currentProfile.type !== USER_ROLES.STUDENT_ROLE
        && currentProfile.type !== USER_ROLES.COUNSELLOR_ROLE)
         || (socket.current && socket.current.connected)) {
      return undefined;
    }

    const accessToken = LocalStorageService.getAccessToken();

    if (!accessToken) {
      return undefined;
    }

    socket.current = io(config.calenderSocketDomain, {
      path: config.calenderSocketPath,
      query: {
        accessToken,
      },
    });

    setAppointmentSocket(socket.current);

    return () => {
      if (socket?.current && socket.current.connected) {
        socket.current.disconnect();
      }
    };
  }, [currentProfile?.id]);

  useEffect(() => {
    (async () => {
      const reportItem = LocalStorageService.getAppointmentReportItem();
      const counsellorId = LocalStorageService.getAppointmentReportCounsellorId();
      const internalReportItem = LocalStorageService.getInternalReportItem();
      if (!reportItem
          || !currentProfile
          || currentProfile.type !== USER_ROLES.COUNSELLOR_ROLE
          || currentProfile.id !== counsellorId) {
        return;
      }

      setAppointmentReportItem(reportItem);
      setInternalReportItem(internalReportItem);
    })();
  }, [currentProfile]);

  const createInternalReport = useCallback(async(data)=>{
    if (!internalReportItem || !currentProfile) {
      return;
    }
    
    const report = {
      accountId: currentProfile.accountId,
      reporterId: currentProfile.userId,
      entityId:internalReportItem.reportItemId,
      type: internalReportItem.type,
      userId:null,
      ...data,
    };
    await ReportService.createReport(currentProfile.accountId, report);
    LocalStorageService.removeInternalReportItem();
    LocalStorageService.removeAppointmentReportCounsellorId();
    setInternalReportItem(null);
  },[internalReportItem,currentProfile])

  const createReport = useCallback(async (data) => {
    if (!appointmentReportItem || !currentProfile) {
      return;
    }

    let entityId;
    let anotherParticipant;
    switch (appointmentReportItem.type) {
      case 'appointment':
        const room = await VideoService.getRoomById(currentProfile.accountId, appointmentReportItem.reportItemId, {
          includeParticipants: true,
        });

        entityId = room.eventId;
        anotherParticipant = room.participants.find((participant) => participant.userId !== currentProfile.userId);
        break;
      case 'chat':
        const chatRoom = await ChatRoomService.getChatRoomById(currentProfile.accountId, appointmentReportItem.reportItemId, true);
        entityId = chatRoom.id;
        anotherParticipant = chatRoom.participants.find((participant) => participant.userId !== currentProfile.userId);
        break;
      case "instant":
        entityId = appointmentReportItem.reportItemId;
        anotherParticipant = {
          userId: appointmentReportItem.userId,
        };
        break;
      case "anonymous_quick_report":
        entityId = appointmentReportItem.reportItemId;
        anotherParticipant ={
          userId:null
        }
        break;
      default:
        LocalStorageService.removeAppointmentReportItem();
        LocalStorageService.removeAppointmentReportCounsellorId();
        setAppointmentReportItem(null);
        return;
    }
    const report = {
      accountId: currentProfile.accountId,
      reporterId: currentProfile.userId,
      entityId,
      type: appointmentReportItem.type,
      userId: anotherParticipant.userId,
      ...data,
    };
    await ReportService.createReport(currentProfile.accountId, report);
    LocalStorageService.removeAppointmentReportItem();
    LocalStorageService.removeAppointmentReportCounsellorId();
    setAppointmentReportItem(null);
  }, [appointmentReportItem, currentProfile]);

  const createAppointmentReportItem = useCallback((reportItem) => {
    LocalStorageService.setAppointmentReportItem(reportItem);
    setAppointmentReportItem(reportItem);
  }, []);

  const createInternalReportItem = useCallback((internalReportItem)=>{
    LocalStorageService.setInternalReportItem(internalReportItem);
    setInternalReportItem(internalReportItem);
  })

  const createAppointmentReportCounsellorId = useCallback((id) => {
    LocalStorageService.setAppointmentReportCounsellorId(id);
  }, []);

  const value = {
    appointmentReportItem,
    createAppointmentReportItem,
    createInternalReportItem,
    createAppointmentReportCounsellorId,
    createReport,
    appointmentSocket,
    internalReportItem,
    createInternalReport,
  };

  return (
    <AppointmentReportContext.Provider value={{...value}}>{children}</AppointmentReportContext.Provider>
  );
};

export default AppointmentReportProvider;
