const keys = {
  accessToken: "accessToken",
  refreshToken: "refreshToken",
  surveyStatus: "surveyStatus",
  appointmentReportItem: "appointmentReportItem",
  appointmentReportCounsellorId: "appointmentReportCounsellorId",
  language: "language",
  chatRooms: "chatRooms",
  liveChatRooms: "liveChatRooms",
  studentLiveChatRooms: "studentLiveChatRooms",
  chatRoomsMessages: "chatRoomsMessages",
  liveChatRoomsMessages: "liveChatRoomsMessages",
  liveChatRoomsMessagesForStudent: "liveChatRoomsMessagesForStudent",
  appointments: "appointments",
  internalReportItem: "internalReportItem",
  deviceId: "deviceId",
};

class LocalStorageService {
  static getAccessToken() {
    const accessToken = localStorage.getItem(keys.accessToken) || "";
    return accessToken;
  }

  static setAccessToken(accessToken) {
    localStorage.setItem(keys.accessToken, accessToken);
  }

  static removeAccessToken() {
    localStorage.removeItem(keys.accessToken);
  }

  static setDeviceId(deviceId) {
    localStorage.setItem(keys.deviceId, deviceId);
  }

  static getDeviceId() {
    return localStorage.getItem(keys.deviceId) || "";
  }

  static removeDeviceId() {
    localStorage.removeItem(keys.deviceId);
  }

  static getRefreshToken() {
    const refreshToken = localStorage.getItem(keys.refreshToken) || "";
    return refreshToken;
  }

  static setRefreshToken(refreshToken) {
    localStorage.setItem(keys.refreshToken, refreshToken);
  }

  static removeRefreshToken() {
    localStorage.removeItem(keys.refreshToken);
  }

  static getSurveyStatus() {
    const status = localStorage.getItem(keys.surveyStatus) || null;
    return status;
  }

  static setSurveyStatus(status) {
    localStorage.setItem(keys.surveyStatus, status);
  }

  static removeSurveyStatus() {
    localStorage.removeItem(keys.surveyStatus);
  }

  static getAppointmentReportItem() {
    const storageValue =
      localStorage.getItem(keys.appointmentReportItem) || null;
    if (storageValue) {
      const reportItem = JSON.parse(storageValue);
      if (
        reportItem &&
        typeof reportItem === "object" &&
        !Array.isArray(reportItem)
      ) {
        return reportItem;
      }
    }

    return null;
  }

  static setAppointmentReportItem(reportItem) {
    const serialized = JSON.stringify(reportItem);
    localStorage.setItem(keys.appointmentReportItem, serialized);
  }

  static removeAppointmentReportItem() {
    localStorage.removeItem(keys.appointmentReportItem);
  }

  static getInternalReportItem() {
    const storageValue = localStorage.getItem(keys.internalReportItem) || null;
    if (storageValue) {
      const reportItem = JSON.parse(storageValue);
      if (
        reportItem &&
        typeof reportItem === "object" &&
        !Array.isArray(reportItem)
      ) {
        return reportItem;
      }
    }

    return null;
  }

  static setInternalReportItem(reportItem) {
    const serialized = JSON.stringify(reportItem);
    localStorage.setItem(keys.internalReportItem, serialized);
  }

  static removeInternalReportItem() {
    localStorage.removeItem(keys.internalReportItem);
  }

  static getAppointmentReportCounsellorId() {
    const reportId =
      localStorage.getItem(keys.appointmentReportCounsellorId) || "";
    return reportId;
  }

  static setAppointmentReportCounsellorId(id) {
    localStorage.setItem(keys.appointmentReportCounsellorId, id);
  }

  static removeAppointmentReportCounsellorId() {
    localStorage.removeItem(keys.appointmentReportCounsellorId);
  }

  static setLanguage(language) {
    localStorage.setItem(keys.language, language);
  }

  static getLanguage() {
    return localStorage.getItem(keys.language) || "sv";
  }

  static setChatRooms(chatRooms) {
    const serialized = JSON.stringify(chatRooms);
    localStorage.setItem(keys.chatRooms, serialized);
  }

  static getChatRooms() {
    const storageValue = localStorage.getItem(keys.chatRooms) || null;
    if (storageValue) {
      const chatRooms = JSON.parse(storageValue);
      if (Array.isArray(chatRooms)) {
        return chatRooms;
      }
    }

    return null;
  }

  static removeChatRooms() {
    localStorage.removeItem(keys.chatRooms);
  }

  static setLiveChatRooms(liveChatRooms) {
    const serialized = JSON.stringify(liveChatRooms);
    localStorage.setItem(keys.liveChatRooms, serialized);
  }

  static getLiveChatRooms() {
    const storageValue = localStorage.getItem(keys.liveChatRooms) || null;
    if (storageValue) {
      const liveChatRooms = JSON.parse(storageValue);
      if (Array.isArray(liveChatRooms)) {
        return liveChatRooms;
      }
    }

    return null;
  }

  static removeLiveChatRooms() {
    localStorage.removeItem(keys.liveChatRooms);
  }

  static setStudentLiveChatRooms(studentLiveChatRooms) {
    const serialized = JSON.stringify(studentLiveChatRooms);
    localStorage.setItem(keys.studentLiveChatRooms, serialized);
  }

  static getStudentLiveChatRooms() {
    const storageValue =
      localStorage.getItem(keys.studentLiveChatRooms) || null;
    if (storageValue) {
      const studentLiveChatRooms = JSON.parse(storageValue);
      if (Array.isArray(studentLiveChatRooms)) {
        return studentLiveChatRooms;
      }
    }

    return null;
  }

  static removeStudentLiveChatRooms() {
    localStorage.removeItem(keys.studentLiveChatRooms);
  }

  static setChatRoomMessages(chatRoomId, messages) {
    const storageValue = localStorage.getItem(keys.chatRoomsMessages) || null;
    if (!storageValue) {
      const serialized = JSON.stringify({ [chatRoomId]: messages });
      localStorage.setItem(keys.chatRoomsMessages, serialized);
      return;
    }

    const chatRoomsMessages = JSON.parse(storageValue);
    if (
      !chatRoomsMessages ||
      typeof chatRoomsMessages !== "object" ||
      Array.isArray(chatRoomsMessages)
    ) {
      const serialized = JSON.stringify({ [chatRoomId]: messages });
      localStorage.setItem(keys.chatRoomsMessages, serialized);
      return;
    }

    chatRoomsMessages[chatRoomId] = messages;
    const serialized = JSON.stringify(chatRoomsMessages);
    localStorage.setItem(keys.chatRoomsMessages, serialized);
  }

  static getChatRoomMessages(chatRoomId) {
    const storageValue = localStorage.getItem(keys.chatRoomsMessages) || null;

    if (storageValue) {
      const chatRoomsMessages = JSON.parse(storageValue);
      if (
        chatRoomsMessages &&
        typeof chatRoomsMessages === "object" &&
        !Array.isArray(chatRoomsMessages)
      ) {
        return chatRoomsMessages[chatRoomId] ?? null;
      }
    }

    return null;
  }

  static setGlobalChatRoomMessages(globalChatRoomId, messages) {
    const storageValue = localStorage.getItem(keys.chatRoomsMessages) || null;
    if (!storageValue) {
      const serialized = JSON.stringify({ [globalChatRoomId]: messages });
      localStorage.setItem(keys.chatRoomsMessages, serialized);
      return;
    }

    const chatRoomsMessages = JSON.parse(storageValue);
    if (
      !chatRoomsMessages ||
      typeof chatRoomsMessages !== "object" ||
      Array.isArray(chatRoomsMessages)
    ) {
      const serialized = JSON.stringify({ [globalChatRoomId]: messages });
      localStorage.setItem(keys.chatRoomsMessages, serialized);
      return;
    }

    chatRoomsMessages[globalChatRoomId] = messages;
    const serialized = JSON.stringify(chatRoomsMessages);
    localStorage.setItem(keys.chatRoomsMessages, serialized);
  }

  static getGlobalChatRoomMessages(globalChatRoomId) {
    const storageValue = localStorage.getItem(keys.chatRoomsMessages) || null;

    if (storageValue) {
      const chatRoomsMessages = JSON.parse(storageValue);
      if (
        chatRoomsMessages &&
        typeof chatRoomsMessages === "object" &&
        !Array.isArray(chatRoomsMessages)
      ) {
        return chatRoomsMessages[globalChatRoomId] ?? null;
      }
    }

    return null;
  }

  static removeChatRoomsMessages() {
    localStorage.removeItem(keys.chatRoomsMessages);
  }

  static setLiveChatMessages(chatRoomId, messages) {
    const storageValue =
      localStorage.getItem(keys.liveChatRoomsMessages) || null;
    if (!storageValue) {
      const serialized = JSON.stringify({ [chatRoomId]: messages });
      localStorage.setItem(keys.liveChatRoomsMessages, serialized);
      return;
    }

    const liveChatRoomsMessages = JSON.parse(storageValue);
    if (
      !liveChatRoomsMessages ||
      typeof liveChatRoomsMessages !== "object" ||
      Array.isArray(liveChatRoomsMessages)
    ) {
      const serialized = JSON.stringify({ [chatRoomId]: messages });
      localStorage.setItem(keys.liveChatRoomsMessages, serialized);
      return;
    }

    liveChatRoomsMessages[chatRoomId] = messages;
    const serialized = JSON.stringify(liveChatRoomsMessages);
    localStorage.setItem(keys.liveChatRoomsMessages, serialized);
  }

  static getLiveChatMessages(chatRoomId) {
    const storageValue =
      localStorage.getItem(keys.liveChatRoomsMessages) || null;

    if (storageValue) {
      const liveChatRoomsMessages = JSON.parse(storageValue);
      if (
        liveChatRoomsMessages &&
        typeof liveChatRoomsMessages === "object" &&
        !Array.isArray(liveChatRoomsMessages)
      ) {
        return liveChatRoomsMessages[chatRoomId] ?? null;
      }
    }

    return null;
  }

  static removeLiveChatRoomsMessages() {
    localStorage.removeItem(keys.liveChatRoomsMessages);
  }

  static setLiveChatMessagesForStudent(chatRoomId, messages) {
    const storageValue =
      localStorage.getItem(keys.liveChatRoomsMessagesForStudent) || null;
    if (!storageValue) {
      const serialized = JSON.stringify({ [chatRoomId]: messages });
      localStorage.setItem(keys.liveChatRoomsMessagesForStudent, serialized);
      return;
    }

    const liveChatRoomsMessages = JSON.parse(storageValue);
    if (
      !liveChatRoomsMessages ||
      typeof liveChatRoomsMessages !== "object" ||
      Array.isArray(liveChatRoomsMessages)
    ) {
      const serialized = JSON.stringify({ [chatRoomId]: messages });
      localStorage.setItem(keys.liveChatRoomsMessagesForStudent, serialized);
      return;
    }

    liveChatRoomsMessages[chatRoomId] = messages;
    const serialized = JSON.stringify(liveChatRoomsMessages);
    localStorage.setItem(keys.liveChatRoomsMessagesForStudent, serialized);
  }

  static getLiveChatMessagesForStudent(chatRoomId) {
    const storageValue =
      localStorage.getItem(keys.liveChatRoomsMessagesForStudent) || null;

    if (storageValue) {
      const liveChatRoomsMessages = JSON.parse(storageValue);
      if (
        liveChatRoomsMessages &&
        typeof liveChatRoomsMessages === "object" &&
        !Array.isArray(liveChatRoomsMessages)
      ) {
        return liveChatRoomsMessages[chatRoomId] ?? null;
      }
    }

    return null;
  }

  static getAllLiveChatMessagesForStudent() {
    const storageValue =
      localStorage.getItem(keys.liveChatRoomsMessagesForStudent) || null;

    if (storageValue) {
      const liveChatRoomsMessages = JSON.parse(storageValue);
      if (
        liveChatRoomsMessages &&
        typeof liveChatRoomsMessages === "object" &&
        !Array.isArray(liveChatRoomsMessages)
      ) {
        return liveChatRoomsMessages;
      }
    }
  }

  static setAllLiveChatMessagesForStudent(liveChatRoomsMessages) {
    const serialized = JSON.stringify(liveChatRoomsMessages);
    localStorage.setItem(keys.liveChatRoomsMessagesForStudent, serialized);
  }

  static removeLiveChatRoomsMessagesForStudent() {
    localStorage.removeItem(keys.liveChatRoomsMessagesForStudent);
  }

  static setAppointments(appointments) {
    const serialized = JSON.stringify(appointments);
    localStorage.setItem(keys.appointments, serialized);
  }

  static getAppointments() {
    const storageValue = localStorage.getItem(keys.appointments) || null;
    if (storageValue) {
      const appointments = JSON.parse(storageValue);
      if (Array.isArray(appointments)) {
        return appointments;
      }
    }

    return null;
  }

  static removeAppointments() {
    localStorage.removeItem(keys.appointments);
  }

  static removeAppCache() {
    LocalStorageService.removeChatRoomsMessages();
    LocalStorageService.removeChatRooms();
    LocalStorageService.removeAppointments();
  }
}

export default LocalStorageService;
