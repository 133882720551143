import { useEffect, useMemo } from "react";
import { AUTH_ROUTES } from "@router/auth";
import { SURVEY_STATUSES, USER_ROLES } from "@utils/consts";
import AuthLayout from "@components/Layout/AuthLayout";
import RootLayout from "@components/Layout/RootLayout";
import { ROOT_ROUTES } from "@router/root";
import { STUDENT_ROUTES } from "@router/student";
import { SURVEY_ROUTES } from "@router/survey";
import SurveyLayout from "@components/Layout/SurveyLayout";
import { COUNSELLOR_ROUTES } from "@router/counsellor";
import { RedirectRoot } from "@pages/RedirectPage";
import { NOT_FOUND, ROOT, SURVEY } from "@router/consts";
import { PRINCIPAL_ROUTES } from "@router/principal";
import { ALLBRY_COUNSELLOR_ROUTES } from "@router/allbryCounsellor";
import Auth from "@pages/Auth";
import CheckInLayout from "@components/Layout/CheckInLayout";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "@services/localStorage";
import { useLocation } from "react-router-dom";
const surveyStatuses = {
  [SURVEY_STATUSES.STUDENT_SURVEY]: SURVEY_ROUTES,
  [SURVEY_STATUSES.STUDENT_GENDER]: SURVEY_ROUTES,
};

export const useRouterPaths = (
  role,
  surveyStatus,
  isMainLoading,
  isCheckInPublic
) => {
  const location = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    if (
      !(
        LocalStorageService.getAccessToken() &&
        LocalStorageService.getRefreshToken()
      )
    ) {
      if (
        location.pathname.startsWith("/meet") ||
        location.pathname === "/join" ||
        location.pathname === "/login" ||
        location.pathname === "/" ||
        location.pathname === "/sso" ||
        location.pathname?.includes("/sign-up-consumer") ||
        location.pathname === "/forgot" ||
        location.pathname === "/login-with-username" ||
        location.pathname === "/sign-up" ||
        location.pathname === "/youth-and-class" ||
        location.pathname === "/anonymous-chat"
      ) {
        return;
      }
      const pathUrl = location.pathname + location.search;
      navigate(
        { pathname: "/login", search: `go=${pathUrl}` },
        { state: { go: pathUrl } }
      );
    }
  }, []);

  return useMemo(() => {
    if (isMainLoading) return [];
    let allRoutes = [];
    let Layout;
    if (isCheckInPublic) {
      Layout = CheckInLayout;
      allRoutes = [
        {
          name: "survey",
          path: SURVEY,
          exact: true,
          element: <Auth.Survey />,
        },
      ];
      return [
        {
          children: allRoutes,
          element: <Layout />,
        },
      ];
    }

    const routeRoles = {
      [USER_ROLES.STUDENT_ROLE]: STUDENT_ROUTES(),
      [USER_ROLES.COUNSELLOR_ROLE]: COUNSELLOR_ROUTES(),
      [USER_ROLES.PRINCIPAL_ROLE]: PRINCIPAL_ROUTES(),
      [USER_ROLES.ALLBRY_COUNSELLOR_ROLE]: ALLBRY_COUNSELLOR_ROUTES,
    };

    if (surveyStatus && surveyStatuses[surveyStatus]) {
      Layout = SurveyLayout;
      allRoutes = allRoutes.concat(allRoutes, surveyStatuses[surveyStatus]);
    }

    if (!surveyStatus && role && routeRoles[role]) {
      Layout =
        role === USER_ROLES.ALLBRY_COUNSELLOR_ROLE ? AuthLayout : RootLayout;
      allRoutes = allRoutes.concat(allRoutes, [
        ...[
          ...routeRoles[role]?.map((item) => {
            return { ...item, state: { go: location.state } };
          }),
        ],
        ...ROOT_ROUTES,
        {
          path: NOT_FOUND,
          element: <RedirectRoot />,
          state: { go: location.state },
        },
      ]);
    }

    if (!surveyStatus && !role) {
      Layout = AuthLayout;
      allRoutes = allRoutes.concat(allRoutes, AUTH_ROUTES);
    }

    return [
      {
        element: <Layout />,
        children: allRoutes,
      },
    ];
  }, [role, surveyStatus, isMainLoading]);
};
