import {
  ACCOUNT,
  ADD_CONFIG,
  ADD_USER, CONFIG, EDIT_USER, ORGANIZATION, STATISTICS, USERS,
} from '@router/consts';
import Principal from '@pages/Principal';
import { ability } from "ability/ability";
import {USER_ROLES, CUSTOM_USER_ROLES} from '@utils/consts';
import {ManyUsers, Statistics, User} from '@assets/icons';
import TemplateIcon from '@assets/icons/Template';

export const PRINCIPAL_ROUTE_NAME = {
  ORGANIZATION: 'organization',
  USERS: 'users',
  STATISTICS: 'statistics',
  CHANGE_USER: 'changeUser',
};

export const PRINCIPAL_MENU_ROUTES = () => {

  let principalMenuRoutes = [];
  
  if (ability.can(CUSTOM_USER_ROLES[USER_ROLES.PRINCIPAL_ROLE].DASHBOARD, USER_ROLES.PRINCIPAL_ROLE)) {
    principalMenuRoutes.push({
      name: 'statistics',
      path: STATISTICS,
      exact: true,
      icon: Statistics,
      isMenu: true,
      element: <Principal.Statistics/>,
    });
  }

  if (ability.can(CUSTOM_USER_ROLES[USER_ROLES.PRINCIPAL_ROLE].USER_MANAGEMENT, USER_ROLES.PRINCIPAL_ROLE)) {
    principalMenuRoutes.push({
      name: 'users',
      path: USERS,
      exact: true,
      icon: User,
      isMenu: true,
      element: <Principal.Users/>,
    });
  }

  if (ability.can(CUSTOM_USER_ROLES[USER_ROLES.PRINCIPAL_ROLE].ORGANIZATION_SETTINGS, USER_ROLES.PRINCIPAL_ROLE)) {
    principalMenuRoutes.push({
      name: 'organization',
      path: ORGANIZATION,
      exact: true,
      icon: ManyUsers,
      isMenu: true,
      element: <Principal.Organization/>,
    });
  }

  if (ability.can(CUSTOM_USER_ROLES[USER_ROLES.PRINCIPAL_ROLE].SURVEY_CONFIG, USER_ROLES.PRINCIPAL_ROLE)) {
    principalMenuRoutes.push({
      name: 'config',
      exact: true,
      isMenu: true,
      icon: TemplateIcon,
      path: CONFIG,
      element: <Principal.Config/>,
    });
  }

  return principalMenuRoutes;
};

export const PRINCIPAL_ROUTES = () => {
  
  let principalRoutes = PRINCIPAL_MENU_ROUTES();

  if (ability.can(CUSTOM_USER_ROLES[USER_ROLES.PRINCIPAL_ROLE].USER_MANAGEMENT, USER_ROLES.PRINCIPAL_ROLE)) {
    principalRoutes.push({
      name: 'addUser',
      path: ADD_USER,
      exact: true,
      element: <Principal.ChangeUser/>,
    });
    principalRoutes.push({
      name: 'editUser',
      path: EDIT_USER,
      exact: true,
      element: <Principal.ChangeUser/>,
    });
    principalRoutes.push({
      name: 'profileUser',
      path: `${USERS}/:id/${ACCOUNT}/:accountId`,
      exact: true,
      element: <Principal.ProfileUser/>,
    });
  }

  if (ability.can(CUSTOM_USER_ROLES[USER_ROLES.PRINCIPAL_ROLE].SURVEY_CONFIG, USER_ROLES.PRINCIPAL_ROLE)) {
    principalRoutes.push({
      name: 'configCreate',
      path: ADD_CONFIG,
      exact: true,
      element: <Principal.ConfigCreate/>,
    });
  }

  return principalRoutes;
};
