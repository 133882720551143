import config from "@config/config";
import ApiService from "./api";

class ChatApi extends ApiService {
  constructor() {
    if (ChatApi.instance) {
      return ChatApi.instance;
    }

    super(config.chatRoomApiUrl);
    ChatApi.instance = this;
  }

  async getChatRoomsByUserId(
    accountId,
    userId,
    populateLastMessage = false,
    populateChatParticipants = false
  ) {
    const userIdIdParam = `userId=${userId}`;
    const populateChatParticipantsParam = `populateChatParticipants=${populateChatParticipants}`;
    const populateLastMessageParam = `populateLastMessage=${populateLastMessage}`;

    const response = await this.http.get(
      `/accounts/${accountId}/chatRooms?${userIdIdParam}${
        populateChatParticipants ? `&${populateChatParticipantsParam}` : ""
      }${populateLastMessage ? `&${populateLastMessageParam}` : ""}`
    );
    return response.data;
  }

  async getChatRoomById(
    accountId,
    chatRoomId,
    populateChatParticipants = false
  ) {
    const populateChatParticipantsParam = `populateChatParticipants=${populateChatParticipants}`;

    const response = await this.http.get(
      `/accounts/${accountId}/chatRooms/${chatRoomId}?${populateChatParticipantsParam}`
    );
    return response.data;
  }

  async createChatRoom(accountId, chatRoom) {
    const response = await this.http.post(
      `/accounts/${accountId}/chatRooms`,
      chatRoom
    );
    return response.data;
  }

  async updateChatRoom(accountId, chatRoom) {
    const response = await this.http.put(
      `/accounts/${accountId}/chatRooms/${chatRoom.id}`,
      chatRoom
    );
    return response.data;
  }

  async deleteChatRoom(accountId, chatRoomId) {
    await this.http.delete(`/accounts/${accountId}/chatRooms/${chatRoomId}`);
  }

  async createChatRoomParticipant(accountId, chatRoomParticipant) {
    const response = await this.http.post(
      `/accounts/${accountId}/chatRooms/${chatRoomParticipant.chatRoomId}/participants`,
      chatRoomParticipant
    );
    return response.data;
  }

  async deleteChatRoomParticipant(accountId, chatRoomId, userId) {
    const response = await this.http.delete(
      `/accounts/${accountId}/chatRooms/${chatRoomId}/participants/${chatRoomId}/${userId}`
    );
    return response.data;
  }

  async createChatRoomMessage(accountId, chatRoomMessage) {
    const response = await this.http.post(
      `/accounts/${accountId}/chatRooms/${chatRoomMessage.chatRoomId}/messages`,
      chatRoomMessage
    );
    return response.data;
  }

  async getChatRoomMessages(accountId, chatRoomId, date = null) {
    const response = await this.http.get(
      `/accounts/${accountId}/chatRooms/${chatRoomId}/messages?date=${date}`
    );
    return response.data;
  }

  async getChatRoomAttachments(accountId, chatRoomId) {
    const response = await this.http.get(
      `/accounts/${accountId}/chatRooms/${chatRoomId}/attachments`
    );
    return response.data;
  }
}

const chatApiInstance = new ChatApi();
export default chatApiInstance;
