import { createContext, useContext, useEffect, useRef, useState } from "react";
import { DeviceContext } from "./Device";
import config from "@config/config";
import {io} from 'socket.io-client';

export const StudentAnonymousLiveChatContext = createContext({
    socketForAnonymousLiveChat: null,
    openedChatRoomId: undefined,
    setOpenedChatRoomId: () => { },
});

const StudentAnonymousLiveChatProvider = ({ children }) => {
    const { deviceId } = useContext(DeviceContext);
    const [openedChatRoomId, setOpenedChatRoomId] = useState();
    const socketForAnonymousLiveChat = useRef();

    useEffect(() => {
        if (
            !deviceId ||
            (socketForAnonymousLiveChat.current && socketForAnonymousLiveChat.current.connected)
        ) {
            return undefined;
        }

        socketForAnonymousLiveChat.current = io(config.liveChatSocketDomain, {
            transports: ['websocket'],
            path: config.liveChatSocketPath,
            query: {
                deviceId,
            },
        });

        return () => {
            if (socketForAnonymousLiveChat?.current && socketForAnonymousLiveChat.current.connected) {
                socketForAnonymousLiveChat.current.disconnect();
            }
        };
    }, [deviceId]);

    const value = { socketForAnonymousLiveChat, openedChatRoomId, setOpenedChatRoomId };

    return (
        <StudentAnonymousLiveChatContext.Provider value={{ ...value }}>{children}</StudentAnonymousLiveChatContext.Provider>
    );
};

export default StudentAnonymousLiveChatProvider;