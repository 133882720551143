import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

// CSS
import './ConfirmationDialog.scss';

function ConfirmationDialog({
  showConfirmDialog,
  header,
  bodyTemplate,
  handleYesEvent,
  handleCloseEvent,
  positiveButtonLabel,
  positiveButtonDisable,
  negativeButtonLabel,
  positiveButtonLoader,
  width,
  height,
  customCSSClass,
}) {
  const {t} = useTranslation();
  const renderFooter = () => (
    <div>
      <Button label={t(negativeButtonLabel)} icon="pi pi-times" onClick={() => handleCloseEvent()} className="p-button-text" />
      <Button label={t(positiveButtonLabel)} icon="pi pi-check" onClick={() => handleYesEvent()} autoFocus disabled={!positiveButtonDisable} loading={positiveButtonLoader} />
    </div>
  );

  return (
    <Dialog header={t(header)} visible={showConfirmDialog} style={{width, height, ...customCSSClass}} footer={renderFooter()} onHide={() => handleCloseEvent(false)}>
      {bodyTemplate && bodyTemplate}
    </Dialog>
  );
}

ConfirmationDialog.defaultProps = {
  showConfirmDialog: false,
  header: 'common.areYouSure',
  positiveButtonLabel: 'Yes',
  positiveButtonDisable: false,
  negativeButtonLabel: 'No',
  positiveButtonLoader: false,
  width: '50vw',
  height: null,
  customCSSClass: {},
};

ConfirmationDialog.propTypes = {
  showConfirmDialog: PropTypes.bool,
  header: PropTypes.string,
  positiveButtonLabel: PropTypes.string,
  positiveButtonDisable: PropTypes.bool,
  negativeButtonLabel: PropTypes.string,
  positiveButtonLoader: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  customCSSClass: PropTypes.shape({}),
};

export default ConfirmationDialog;
