import { useState, useEffect, useRef, useContext } from "react";
import clsx from "clsx";
import { Chat } from "@assets/icons";
import { useLiveChatList } from "@hooks/chats";
import messageSound from "../../../assets/sounds/Message.mp3";
import { AnonymousLiveChatContext } from "@contexts/AnonymousLiveChat";

const AnonymousLiveChatIcon = ({ isActiveTab, className, ...props }) => {
  const isMounted = useRef(false);
  const soundRef = useRef(new Audio(messageSound));
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(null);
  const [chatListRefresh, setChatListRefresh] = useState(false);
  const [liveChatList, { isLoading }] = useLiveChatList({
    allowReadMessageEventEmitting: false,
    chatListRefresh,
  });

  const { socketForAnonymousLiveChat } = useContext(AnonymousLiveChatContext);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    const liveChatListResult =
      !isLoading &&
      liveChatList
        .map((chatRoom) => chatRoom.unreadMessageCount)
        .reduce((prev, cur) => prev + cur, 0);

    if (
      unreadMessagesCount !== null &&
      liveChatListResult > unreadMessagesCount
    ) {
      soundRef.current?.play().catch(() => {});
    }

    setUnreadMessagesCount(liveChatListResult);
  }, [liveChatList, socketForAnonymousLiveChat]);

  return (
    <div className="relative">
      {unreadMessagesCount && unreadMessagesCount > 0 ? (
        <div
          className={clsx(
            "absolute -right-2/5 -top-35% h-6 w-6 bg-primary rounded-full flex items-center justify-center border-1.5",
            isActiveTab ? "border-pampas" : "border-main"
          )}
        >
          <span className="text-white font-light-poppins font-black text-sm leading-5">
            {unreadMessagesCount}
          </span>
        </div>
      ) : null}
      <Chat className={className} {...props} />
    </div>
  );
};

export default AnonymousLiveChatIcon;
