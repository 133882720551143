import NavbarList from "../NavbarList";
import { useScreenSize } from "@hooks/navbar";
const ResponsiveNavbar = ({ navItems, footerProfileHeight, logoHeight }) => {
    const [{ isMdOrLargerScreen }] = useScreenSize();
    return (
        <>
            {isMdOrLargerScreen ? (
                <div style={{ height: `calc(100vh - ${footerProfileHeight + logoHeight}px)`, overflow: "auto" }}>
                    <NavbarList navItems={navItems} />
                </div>
            ) : (
                <NavbarList navItems={navItems} />
            )}
        </>
    );
};

export default ResponsiveNavbar;
