import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import "./i18n";
import UserProvider from "./contexts/User";
import ChatProvider from "./contexts/Messages";
import AppointmentReportProvider from "./contexts/AppointmentReport";
import { AbilityContext } from "ability/Can";
import { ability } from "ability/ability";
import GlobalChatProvider from "@contexts/GlobalMessages";
import AnonymousLiveChatProvider from "@contexts/AnonymousLiveChat";
import DeviceProvider from "@contexts/Device";
import StudentAnonymousLiveChatProvider from "@contexts/StudentAnonymousLiveChat";

// ***** PrimeReact *****
import "primereact/resources/themes/tailwind-light/theme.css"; // Theme : tailwind-light
import "primereact/resources/primereact.min.css"; // PrimeReact Core css
import "primeicons/primeicons.css"; // PrimeReact Icons
import ErrorBoundary from "@pages/ErrorBoundary";
import NotFound from "@pages/NotFound";
import AdminProvider from "@admin/contexts/AdminContext";
import AdminView from "@admin/App";
import NavbarProvider from "@contexts/Navbar";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Define user routes
const userRouter = createBrowserRouter([
  {
    path: "/*",
    element: (
      <UserProvider>
        <DeviceProvider>
          <AppointmentReportProvider>
            <ChatProvider>
              <GlobalChatProvider>
                <AnonymousLiveChatProvider>
                  <StudentAnonymousLiveChatProvider>
                    <NavbarProvider>
                      <App />
                    </NavbarProvider>
                  </StudentAnonymousLiveChatProvider>
                </AnonymousLiveChatProvider>
              </GlobalChatProvider>
            </ChatProvider>
          </AppointmentReportProvider>
        </DeviceProvider>
      </UserProvider>
    ),
    errorElement: <ErrorBoundary />,  // Error handling for user routes
  },
  {
    path: "*",  // Catch-all route for undefined paths (404)
    element: <NotFound />,  // Render NotFoundPage for undefined routes
  },
]);

// Define admin routes
const adminRouter = createBrowserRouter([
  {
    path: "/*",  // Match all admin-related routes
    element: (
      <AdminProvider>
        <AdminView />
      </AdminProvider>
    ),  // Admin-specific component
    errorElement: <ErrorBoundary />,  // Error handling for admin routes
  },
  {
    path: "*",  // Catch-all route for undefined paths (404)
    element: <NotFound />,  // Render NotFoundPage for undefined routes
  },
]);

// Conditionally render the admin or user router based on the environment variable
const router = process.env.REACT_APP_TYPE === "admin" ? adminRouter : userRouter;

root.render(
  <AbilityContext.Provider value={ability}>
    <RouterProvider router={router} />
  </AbilityContext.Provider>
);
