import clsx from "clsx";

const LogoIcon = ({
  size = "w-50 h-50",
  viewBox = "-10 -20 120 50",
  className,
  ...props
}) => (
  <svg
    className={clsx(size, className)}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    {...props}
  >
    <g clipPath="url(#clip0_5350_8955)">
      <path
        d="M6.30065 4.54454C6.17837 3.04472 5.37281 2.61044 4.68713 2.61044C3.71134 2.61044 3.1743 3.23932 3.1743 4.32621C3.1743 7.29975 9.3287 8.726 9.3287 13.4628C9.3287 16.339 7.37473 17.9337 4.54327 17.9337C1.7334 17.9337 0.122273 15.7101 0 13.0997L2.8818 12.6891C3.00408 14.4547 3.71134 15.3233 4.68952 15.3233C5.73963 15.3233 6.4469 14.768 6.4469 13.7286C6.4469 10.2733 0.292496 9.3525 0.292496 4.34995C0.292496 1.57101 2.00192 0 4.98202 0C7.44905 0 8.91393 1.76561 9.18245 4.22892L6.30065 4.54454Z"
        fill="black"
      />
      <path
        d="M13.1368 2.85263H10.207V0.242188H19.0946V2.85263H16.1648V17.6918H13.1368V2.85263Z"
        fill="black"
      />
      <path
        d="M20.4961 4.47098C20.4961 2.00529 21.961 0 25.1353 0C28.3096 0 29.7744 2.00529 29.7744 4.47098V13.4628C29.7744 15.9284 28.3096 17.9337 25.1353 17.9337C21.961 17.9337 20.4961 15.9284 20.4961 13.4628V4.47098ZM23.5217 13.3892C23.5217 14.6944 23.9125 15.3233 25.1329 15.3233C26.3532 15.3233 26.744 14.6944 26.744 13.3892V4.54454C26.744 3.23932 26.3532 2.61044 25.1329 2.61044C23.9125 2.61044 23.5217 3.23932 23.5217 4.54454V13.3892Z"
        fill="black"
      />
      <path
        d="M35.5554 17.6918H32.5273V0.242188H36.7757C40.1706 0.242188 41.904 1.66844 41.904 5.3895C41.904 8.19217 40.8059 9.3289 39.7798 9.78928L42.3427 17.6918H39.2667L37.1186 10.4656C36.7278 10.5131 36.0924 10.5392 35.5554 10.5392V17.6918ZM35.5554 8.07351H36.6295C38.2646 8.07351 38.8759 7.46836 38.8759 5.3895C38.8759 3.31064 38.2646 2.7055 36.6295 2.7055H35.5554V8.07351Z"
        fill="black"
      />
      <path
        d="M50.1318 4.54454C50.0095 3.04472 49.204 2.61044 48.5207 2.61044C47.5449 2.61044 47.0078 3.23932 47.0078 4.32621C47.0078 7.29975 53.1622 8.726 53.1622 13.4628C53.1622 16.339 51.2083 17.9337 48.3768 17.9337C45.5693 17.9337 43.9582 15.7101 43.8359 13.0997L46.7177 12.6891C46.84 14.4547 47.5473 15.3233 48.5255 15.3233C49.5756 15.3233 50.2828 14.768 50.2828 13.7286C50.2828 10.2733 44.1284 9.3525 44.1284 4.34995C44.1236 1.57101 45.8331 0 48.8132 0C51.2802 0 52.7451 1.76561 53.0136 4.22892L50.1318 4.54454Z"
        fill="black"
      />
      <path
        d="M57.0743 2.85263H54.1445V0.242188H63.0321V2.85263H60.1023V17.6918H57.0743V2.85263Z"
        fill="black"
      />
      <path
        d="M65.0586 17.6918V0.242188H68.0866V7.34733H71.3089V0.242188H74.3369V17.6918H71.3089V9.95778H68.0866V17.6918H65.0586Z"
        fill="black"
      />
      <path
        d="M77.7305 17.6918V0.242188H80.7585V15.0814H85.52V17.6918H77.7305Z"
        fill="black"
      />
      <path
        d="M99.9998 0.242188V17.6918H97.3625V4.06055H97.3146L94.579 17.6918H93.1141L90.3786 4.06055H90.3306V17.6918H87.6934V0.242188H91.6732L93.8214 10.4419H93.8693L96.0175 0.242188H99.9998Z"
        fill="black"
      />
      <path
        d="M12.3872 26.1776H11.9316V22.1006H12.3872V24.2198H12.3992L14.1062 22.1006H14.648L13.3701 23.636L14.8015 26.1776H14.2812L13.0537 24.0086L12.3848 24.8107V26.1776H12.3872Z"
        fill="black"
      />
      <path
        d="M17.0951 22.0435C18.1044 22.0435 18.7038 22.7768 18.7038 24.1389C18.7038 25.5011 18.1044 26.2344 17.0951 26.2344C16.0857 26.2344 15.4863 25.5011 15.4863 24.1389C15.4863 22.7768 16.0857 22.0435 17.0951 22.0435ZM17.0951 25.85C17.8191 25.85 18.2483 25.297 18.2483 24.1389C18.2483 22.9808 17.8191 22.4279 17.0951 22.4279C16.371 22.4279 15.9419 22.9808 15.9419 24.1389C15.9419 25.297 16.371 25.85 17.0951 25.85Z"
        fill="black"
      />
      <path
        d="M23.0627 26.1776V22.4494H23.0459L21.8927 26.1776H21.5379L20.3799 22.4494H20.3631V26.1776H19.9531V22.1006H20.6604L21.7105 25.3992H21.7225L22.7558 22.1006H23.4751V26.18H23.0627V26.1776Z"
        fill="black"
      />
      <path
        d="M28.0666 26.1776V22.4494H28.0498L26.8966 26.1776H26.5418L25.3838 22.4494H25.367V26.1776H24.957V22.1006H25.6643L26.7144 25.3992H26.7264L27.7597 22.1006H28.479V26.18H28.0666V26.1776Z"
        fill="black"
      />
      <path
        d="M30.4087 22.1006V24.9816C30.4087 25.4562 30.7515 25.8525 31.3964 25.8525C32.0414 25.8525 32.3842 25.4562 32.3842 24.9816V22.1006H32.8397V24.9483C32.8397 25.7623 32.2451 26.2369 31.3964 26.2369C30.5453 26.2369 29.9531 25.7623 29.9531 24.9483V22.1006H30.4087Z"
        fill="black"
      />
      <path
        d="M34.3125 26.1776V22.1006H34.9694L36.7675 25.2426H36.7891V22.1006H37.1991V26.18H36.8227L34.7345 22.542H34.7225V26.18H34.3125V26.1776Z"
        fill="black"
      />
      <path
        d="M41.1491 22.1006V22.485H39.1352V23.852H40.5665V24.2364H39.1352V25.7955H41.2642V26.18H38.6797V22.1006H41.1491Z"
        fill="black"
      />
      <path
        d="M42.4023 26.1776V22.1006H43.7258C44.6152 22.1006 45.1019 22.4969 45.1019 23.1732C45.1019 23.7499 44.7663 24.0774 44.2796 24.2364L45.1523 26.18H44.656L43.8217 24.3266H42.8579V26.18H42.4023V26.1776ZM42.8603 23.9421H43.6874C44.3156 23.9421 44.6464 23.7096 44.6464 23.197C44.6464 22.6488 44.27 22.485 43.6826 22.485H42.8603V23.9421Z"
        fill="black"
      />
      <path d="M49.3637 22.1006V26.18H48.9082V22.1006H49.3637Z" fill="black" />
      <path
        d="M55.2763 23.1398C55.1732 22.7435 54.8471 22.4279 54.3173 22.4279C53.8378 22.4279 53.5525 22.6534 53.5477 23.0615C53.5357 23.9538 55.8637 23.7284 55.8637 25.0383C55.8637 25.6316 55.528 26.2368 54.4779 26.2368C53.5765 26.2368 53.2216 25.6601 53.0059 25.1736L53.423 25.0265C53.5885 25.456 53.8977 25.85 54.5019 25.85C55.1061 25.85 55.4082 25.5343 55.4082 25.1167C55.4082 24.0772 53.0922 24.3834 53.0922 23.0948C53.0922 22.5062 53.5885 22.0435 54.3317 22.0435C54.9718 22.0435 55.4729 22.3757 55.6911 23.0497L55.2763 23.1398Z"
        fill="black"
      />
      <path
        d="M56.6934 26.1776L57.9592 22.0981H58.6377L59.9036 26.1776H59.4241L59.0357 24.9008H57.5565L57.1681 26.1776H56.6934ZM57.6739 24.5187H58.923L58.3285 22.4161H58.2661L57.6739 24.5187Z"
        fill="black"
      />
      <path
        d="M64.1057 26.1776V22.4494H64.0889L62.9357 26.1776H62.5808L61.4228 22.4494H61.4061V26.1776H60.9961V22.1006H61.7034L62.7535 25.3992H62.7655L63.7988 22.1006H64.518V26.18H64.1057V26.1776Z"
        fill="black"
      />
      <path
        d="M65.5957 22.1006H66.0512L67.0678 25.4443H67.0798L68.1011 22.1006H68.5638L67.2859 26.18H66.8688L65.5957 22.1006Z"
        fill="black"
      />
      <path
        d="M72.1042 22.1006V22.485H70.0903V23.852H71.524V24.2364H70.0903V25.7955H72.2193V26.18H69.6348V22.1006H72.1042Z"
        fill="black"
      />
      <path
        d="M73.3555 26.1776V22.1006H74.6789C75.5684 22.1006 76.0551 22.4969 76.0551 23.1732C76.0551 23.7499 75.7194 24.0774 75.2327 24.2364L76.1054 26.18H75.6091L74.7748 24.3266H73.811V26.18H73.3555V26.1776ZM73.811 23.9421H74.6381C75.2663 23.9421 75.5971 23.7096 75.5971 23.197C75.5971 22.6488 75.2207 22.485 74.6333 22.485H73.811V23.9421Z"
        fill="black"
      />
      <path
        d="M77.8696 26.1776H77.4141V22.1006H77.8696V24.2198H77.8816L79.5886 22.1006H80.1304L78.8526 23.636L80.2839 26.1776H79.7636L78.5361 24.0086L77.8672 24.8107V26.1776H77.8696Z"
        fill="black"
      />
      <path
        d="M81.207 26.1776L82.4729 22.0981H83.1514L84.4173 26.1776H83.9378L83.5494 24.9008H82.0701L81.6817 26.1776H81.207ZM82.1876 24.5187H83.4367L82.8421 22.4161H82.7798L82.1876 24.5187Z"
        fill="black"
      />
      <path
        d="M85.5332 26.1776V22.1006H86.1901L87.9882 25.2426H88.0098V22.1006H88.4198V26.18H88.0434L85.9552 22.542H85.9432V26.18H85.5332V26.1776Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_5350_8955">
        <rect width="100" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoIcon;
