import { createContext, useCallback, useRef, useState } from "react";
export const NavbarContext = createContext({
    navbarRef: null,
    showNavbar: true,
    handleShowNavbar: (bool) => { },
});

const NavbarProvider = ({ children }) => {
    const navbarRef = useRef(null);
    const [showNavbar, setShowNavbar] = useState(true);

    const handleShowNavbar = useCallback((bool) => {
        setShowNavbar(bool);
    }, [showNavbar]);

    const value = {
        showNavbar,
        handleShowNavbar,
        navbarRef
    };

    return (
        <NavbarContext.Provider value={{ ...value }}>
            {children}
        </NavbarContext.Provider>
    );
};

export default NavbarProvider;